<template>
  <dialog-form v-model="dialog" :max-width="maxWidth" @ok="ok">
    <div slot="fields">
      <v-container v-if="item">
        <v-row>
          <v-col cols="12">
            <v-select
                v-model="item.type"
                label="Tipo do equipamento"
                :items="ceoItemTypes"
                item-value="type"
                item-text="description"
                :rules="rulesItemType"
            />
            <template v-if="isCustomItem">
              <v-text-field
                  v-model="item.name"
                  label="Nome do equipamento"
                  :maxLength="maxCustomEquipmentName"
                  :counter="maxCustomEquipmentName"
                  :rules="rulesCustomEquipmentName"
              />
              <v-text-field
                  v-model.number="item.cable"
                  label="Saídas"
                  :min="minCustomEquipmentOutputs"
                  :max="maxCustomEquipmentOutputs"
                  type="number"
                  :rules="rulesCustomEquipmentOutputs"
              />
            </template>
            <v-text-field
                v-if="isBalancedSplitter"
                v-model.number="item.slice"
                label="Saídas"
                :min="minSplitterOutputs"
                :max="maxSplitterOutputs"
                type="number"
                :rules="rulesSplitterOutputs"
            />
            <v-text-field
                v-if="isUnbalancedSplitter"
                v-model.number="item.cable"
                label="Menor potência de saída"
                :min="minSplitterFactor"
                :max="maxSplitterFactor"
                type="number"
                :rules="rulesSplitterFactor"
            />
          </v-col>
        </v-row>
      </v-container>
    </div>
  </dialog-form>
</template>

<script>
import DialogForm from '@/components/DialogForm';
import {
  CEO_ITEM_TYPE_BALANCED_SPLITTER,
  CEO_ITEM_TYPE_CUSTOM,
  CEO_ITEM_TYPE_UNBALANCED_SPLITTER,
  ceoItemTypes,
  getSplitterUnbalancedMajorOutputFromMinorOutput
} from '@/models/point';
import {
  getCEOSplitterFactor,
  getCEOSplitterOutputs,
  getCustomEquipmentName, getCustomEquipmentOutput,
  getSpliceBoxItemType
} from '@/lib/validation/validation';
import {copy} from '@/lib/util';
import {
  MAX_CUSTOM_EQUIPMENT_NAME,
  MAX_CUSTOM_EQUIPMENT_OUTPUTS,
  MAX_SPLITTER_FACTOR,
  MAX_SPLITTER_OUTPUTS,
  MIN_CUSTOM_EQUIPMENT_OUTPUTS,
  MIN_SPLITTER_FACTOR,
  MIN_SPLITTER_OUTPUTS
} from '@/lib/validation/constants';

const INVALID_TYPE = 'Tipo de equipamento inválido';
const INVALID_OUTPUTS = 'Número inválido de saídas';
const INVALID_FACTOR = 'Potência inválida';
const INVALID_EQUIPMENT_NAME = 'Nome inválido';

export default {
  name: 'SpliceBoxItem',
  components: {DialogForm},
  data() {
    return {
      maxSplitterOutputs: MAX_SPLITTER_OUTPUTS,
      minSplitterOutputs: MIN_SPLITTER_OUTPUTS,
      minSplitterFactor: MIN_SPLITTER_FACTOR,
      maxSplitterFactor: MAX_SPLITTER_FACTOR,
      minCustomEquipmentOutputs: MIN_CUSTOM_EQUIPMENT_OUTPUTS,
      maxCustomEquipmentOutputs: MAX_CUSTOM_EQUIPMENT_OUTPUTS,
      maxCustomEquipmentName: MAX_CUSTOM_EQUIPMENT_NAME,
      ceoItemTypes: ceoItemTypes,
      refItem: null,
      maxWidth: '400',
      dialog: false,
      item: null,
      rulesItemType: [
        v => getSpliceBoxItemType(v) !== null || INVALID_TYPE
      ],
      rulesSplitterOutputs: [
        v => getCEOSplitterOutputs(v) !== null || INVALID_OUTPUTS
      ],
      rulesSplitterFactor: [
        v => getCEOSplitterFactor(v) !== null || INVALID_FACTOR
      ],
      rulesCustomEquipmentName: [
        v => getCustomEquipmentName(v) !== null || INVALID_EQUIPMENT_NAME
      ],
      rulesCustomEquipmentOutputs: [
        v => getCustomEquipmentOutput(v) !== null || INVALID_OUTPUTS
      ]
    }
  },
  methods: {
    open(item) {
      this.refItem = item;
      this.item = copy(item);
      this.dialog = true;
    },
    ok() {
      if (this.isBalancedSplitter) {
        this.item.cable = 1;
      } else if (this.isUnbalancedSplitter) {
        this.item.slice = getSplitterUnbalancedMajorOutputFromMinorOutput(this.item.cable);
      } else if (this.isCustomItem) {
        this.item.slice = 0;
      }
      this.$emit('output', this.item, this.refItem);
      this.dialog = false;
    }
  },
  computed: {
    isBalancedSplitter() {
      return this.item && this.item.type === CEO_ITEM_TYPE_BALANCED_SPLITTER;
    },
    isUnbalancedSplitter() {
      return this.item && this.item.type === CEO_ITEM_TYPE_UNBALANCED_SPLITTER;
    },
    isCustomItem() {
      return this.item && this.item.type === CEO_ITEM_TYPE_CUSTOM;
    }
  },
  watch: {
    'item.type': function (v) {
      if (this.item) {
        if (v === CEO_ITEM_TYPE_BALANCED_SPLITTER) {
          this.item.cable = 1;
          this.item.slice = 2;
        } else if (v === CEO_ITEM_TYPE_UNBALANCED_SPLITTER) {
          this.item.cable = 1;
          this.item.slice = 99;
        } else if (v === CEO_ITEM_TYPE_CUSTOM) {
          this.item.cable = 2;
          this.item.slice = 2;
        }
      }
    }
  }
}
</script>
