import {
    DEFAULT_COLOR,
    DEFAULT_LAT,
    DEFAULT_LNG,
    DEFAULT_NOTES,
    DEFAULT_POINT_COORDINATE_NAME,
    DEFAULT_POINT_COORDINATE_RESERVE,
    DEFAULT_POINT_LENGTH,
    DEFAULT_POINT_NAME,
    MIN_FIBER_SPLICE_BOX_HEIGHT_CONNECTION
} from '@/lib/validation/constants';
import {updateObject} from '@/lib/util';

export const getNewPoint = (fields, array) => {
    return updateObject({
        id: 0, color: DEFAULT_COLOR, length: DEFAULT_POINT_LENGTH, name: DEFAULT_POINT_NAME, coordinates: []
    }, fields, array);
};

export const getNewPointCoordinates = (fields, array) => {
    return updateObject({
        id: 0,
        coordinates: {
            lat: DEFAULT_LAT, lng: DEFAULT_LNG
        },
        name: DEFAULT_POINT_COORDINATE_NAME,
        notes: DEFAULT_NOTES,
        reserve: DEFAULT_POINT_COORDINATE_RESERVE,
        ceo: getNewPointCoordinatesCEO()
    }, fields, array);
};

export const getNewPointCoordinatesCEO = (fields, array) => {
    return updateObject({
        items: [], connections: []
    }, fields, array);
};

export const getNewPointCEOItem = (fields, array) => {
    return updateObject({
        id: 0, name: '', type: CEO_ITEM_TYPE_CABLE, cable: 0, slice: 0
    }, fields, array);
};

export const getNewPointCEOConnection = (fields, array) => {
    return updateObject({
        id: 0, fiber1: '', fiber2: '', height: MIN_FIBER_SPLICE_BOX_HEIGHT_CONNECTION, color: DEFAULT_COLOR
    }, fields, array);
};

export const getSplitterUnbalancedMajorOutputFromMinorOutput = (minorOutput) => {
    return 100 - minorOutput;
}

export const CEO_ITEM_TYPE_CABLE = 1;
export const CEO_ITEM_TYPE_BALANCED_SPLITTER = 2;
export const CEO_ITEM_TYPE_UNBALANCED_SPLITTER = 3;
export const CEO_ITEM_TYPE_CUSTOM = 4;

export const ceoItemTypes = [{
    type: CEO_ITEM_TYPE_BALANCED_SPLITTER, description: 'Splitter balanceado'
}, {
    type: CEO_ITEM_TYPE_UNBALANCED_SPLITTER, description: 'Splitter desbalanceado'
}, {
    type: CEO_ITEM_TYPE_CUSTOM, description: 'Equipamento customizado'
}];
