<template>
  <v-dialog v-model="dialog" max-width="200">
    <v-list>
      <v-list-item v-if="splices" link @click="splice">
        Equipamentos
      </v-list-item>
      <v-list-item link @click="edit">
        Editar
      </v-list-item>
      <v-list-item link @click="remove">
        Remover
      </v-list-item>
    </v-list>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean,
    editAction: Function,
    removeAction: Function,
    splices: {
      type: Boolean,
      required: false,
      default: false
    },
    splicesAction: {
      type: Function,
      required: false,
      default: null
    }
  },
  name: 'ItemMenuContext',
  methods: {
    splice() {
      if (this.splicesAction) {
        this.splicesAction();
      }
    },
    edit() {
      if (this.editAction) {
        this.editAction();
      }
    },
    remove() {
      if (this.removeAction) {
        this.removeAction();
      }
    }
  },
  computed: {
    dialog: {
      get: function () {
        return this.value;
      },
      set: function (val) {
        this.$emit('input', val);
      }
    }
  }
}
</script>
