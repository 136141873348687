<template>
  <div class="fill-height">
    <app-nav-bar title-bar="Projetos" action-menu :back-route="goToHome"/>

    <project-properties ref="createProject" label="Novo projeto" @output="createProject"/>

    <v-main>
      <v-container>
        <v-row justify="center" align="center">
          <v-col cols="12" lg="10">
            <v-card tile flat outlined>
              <v-card-text>
                <div>
                  <span class="font-weight-bold">Quantidade de projetos:</span> {{ projects.length }}
                </div>
                <div>
                  <span class="font-weight-bold">Quantidade máxima de projetos:</span> {{ config.max_projects }}
                </div>
                <div>
                  <span class="font-weight-bold">Quantidade de projetos que você colabora:</span>
                  {{ projectsParticipant.length }}
                </div>
              </v-card-text>
              <v-divider/>
              <v-card-actions>
                <v-spacer/>
                <v-btn @click="loadProjects" text>
                  <v-icon class="mr-3" :color="isDarkMode ? '' : 'primary'">mdi-refresh</v-icon>
                  Atualizar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>

          <v-col cols="12" lg="10">
            <v-card flat outlined>
              <v-tabs show-arrows>
                <v-tab>Meus projetos</v-tab>
                <v-tab>Colaborador</v-tab>

                <v-tab-item>
                  <projects-tab :headers="headers" :projects="projects" @open-project="openProject"/>
                </v-tab-item>

                <v-tab-item>
                  <projects-tab :headers="headers" :projects="projectsParticipant" @open-project="openProject"/>
                </v-tab-item>
              </v-tabs>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <v-btn v-if="allowCreateProject" :color="isDarkMode ? '' : 'primary'" fixed right bottom fab
           @click="openCreateProjectDialog">
      <v-icon>mdi-plus</v-icon>
    </v-btn>
  </div>
</template>

<script>
import AppNavBar from '@/components/AppNavBar';
import {mapActions, mapState} from 'vuex';
import {getProjects, postProject} from '@/lib/backend/project';
import {goToHome, goToProject} from '@/lib/redirects';
import ProjectsTab from '@/views/projects/ProjectsTab';
import ProjectProperties from '@/components/ProjectProperties';

export default {
  name: 'Projects',
  components: {ProjectProperties, ProjectsTab, AppNavBar},
  data() {
    return {
      goToHome: goToHome,
      loading: false,
      projects: [],
      projectsParticipant: [],
      headers: [
        {text: 'Nome', value: 'name', width: '200'},
        {text: 'Proprietário', value: 'user_name', width: '200'},
        {text: 'Criado em', value: 'created_at', width: '170'},
        {text: 'Atualizado em', value: 'updated_at', width: '170'},
        {text: '', value: 'action', sortable: false, width: '135'}
      ]
    }
  },
  mounted() {
    this.loadProjects();
  },
  computed: {
    ...mapState({
      user: state => state.config.user,
      config: state => state.config.config
    }),
    isDarkMode() {
      return this.$vuetify.theme.dark;
    },
    allowCreateProject() {
      if (this.config.max_projects) {
        if (this.config.max_projects < 0) {
          return true;
        } else if (this.projects.length < this.config.max_projects) {
          return true;
        }
      }
      return false;
    }
  },
  methods: {
    ...mapActions('confirmation', ['showConfirmation']),
    ...mapActions('alert', ['showAlert']),
    async loadProjects() {
      const response = await getProjects();
      if (response) {
        if (response.status === 200) {
          this.projects = response.data.owner;
          this.projectsParticipant = response.data.participant;
        } else {
          this.showAlert({message: response.message});
        }
      }
    },
    openCreateProjectDialog() {
      this.$refs.createProject.open({
        name: ''
      });
    },
    openProject(id) {
      goToProject(id);
    },
    async createProject(project) {
      const response = await postProject(project);
      if (response) {
        if (response.status === 200) {
          this.projects.push(response.data);
        } else {
          this.showAlert({message: response.message});
        }
      }
    }
  }
}
</script>
