import {client, request} from '@/lib/backend/http';

export const getPermissions = (projectId) => {
    return request(client().get, `/project/${projectId}/permission`, null, true, false);
};

export const getUserByEmail = (data) => {
    return request(client().post, '/permission/user-by-email', data, true, false);
};

export const postPermission = (projectId, permission) => {
    return request(client().post, `/project/${projectId}/permission`, permission, true, false);
};

export const deletePermission = (projectId, permissionId, overlay = false) => {
    return request(client().delete, `/project/${projectId}/permission/${permissionId}`, null, true, overlay);
};
