<template>
  <div class="pl-2 pr-2" style="color: white;">
    <div class="cable-header" :style="getHeaderStyle(item)">
      <v-icon v-if="!isCable(item) && !readOnly" class="panzoom-exclude" :color="dark ? colorLight : colorDark" large
              @click="deleteItem(item)">
        mdi-delete
      </v-icon>
    </div>
    <div class="fiber-header" :style="getFiberHeaderStyle(item)">
      <div class="pl-2 pr-2 horizontal-list justify-center align-center fill-height">
        <v-btn class="panzoom-exclude" width="10" height="10" icon text dark @click="sort(true)">
          <v-icon size="10">mdi-arrow-left</v-icon>
        </v-btn>
        <div class="pl-1 pr-1">
          <div>
            {{ getHeaderLabel(item) }}
          </div>
          <div style="font-size: 10px">
            {{ getDescriptionHeader(item) }}
          </div>
        </div>
        <v-btn class="panzoom-exclude" width="10" height="10" icon text dark @click="sort(false)">
          <v-icon size="10">mdi-arrow-right</v-icon>
        </v-btn>
      </div>
    </div>

    <div class="horizontal-list justify-center">
      <div class="pl-2 pr-2" :key="`group-${i}`" v-for="i in getNumbersOfGroups(item)">
        <div class="group-fibers" :style="getGroupStyle(item, i)">{{ isCable(item) ? i : '' }}</div>
        <div class="horizontal-list justify-center">
          <div class="fibers" :key="`fiber-${j}`" v-for="j in getFibersFromGroup(item, i)">
            <div :style="getFiberStyle(item, i, j)"/>
            <div class="dot panzoom-exclude"
                 :id="getFiberElementId(item, i, j)"
                 @click="fiberConnect(getFiberElementId(item, i, j))"
                 :style="getDotStyle(getFiberElementId(item, i, j))">
              {{ j }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  CEO_ITEM_TYPE_BALANCED_SPLITTER,
  CEO_ITEM_TYPE_CABLE,
  CEO_ITEM_TYPE_CUSTOM,
  CEO_ITEM_TYPE_UNBALANCED_SPLITTER
} from '@/models/point';
import {CABLE_HEIGHT, FIBER_HEIGHT, FIBER_WIDTH, findItem, isCable} from '@/lib/spliceBox';
import {getColorFiber, getColorFiberSplitterUnbalanced, getColorGroupFiber} from '@/models/cable';

const SPLITTER_NAME = 'Splitter';
const COLOR_DARK = '#000000';
const COLOR_LIGHT = '#EEEEEE';

export default {
  props: {
    item: Object,
    inputSlices: Array,
    showIdSlice: {
      type: Boolean,
      required: false,
      default: false
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    },
    dark: {
      type: Boolean,
      required: false,
      default: false
    },
    fiber1: {
      type: String,
      required: false,
      default: null
    }
  },
  name: 'SpliceBoxItem',
  data() {
    return {
      isCable: isCable,
      colorDark: COLOR_DARK,
      colorLight: COLOR_LIGHT
    }
  },
  methods: {
    infoFromNotes(notes) {
      if (notes) {
        const array = notes.split('\n');
        let info = '';

        if (array.length > 0) {
          info = array[0];
        } else {
          info = notes;
        }

        if (info) {
          return info.length < 30 ? info : info.substring(0, 30);
        }
      }
      return '';
    },
    getFiberHeaderStyle() {
      return `
      background-color: ${COLOR_DARK};
      color: ${COLOR_LIGHT};
      `;
    },
    getBackgroundDotColor(id) {
      if (this.fiber1 === id) {
        return this.dark ? COLOR_DARK : COLOR_LIGHT;
      }
      return this.dark ? COLOR_LIGHT : COLOR_DARK;
    },
    getDotStyle(id) {
      return `
      height: ${FIBER_WIDTH}px;
      width: ${FIBER_WIDTH}px;
      font-size: ${Math.ceil(FIBER_WIDTH) / 3}px;
      background-color: ${this.getBackgroundDotColor(id)};
      color: ${this.dark ? COLOR_DARK : COLOR_LIGHT};
      `;
    },
    getHeaderStyle(item) {
      let style = `height: ${CABLE_HEIGHT}px;`;
      if (item.type === CEO_ITEM_TYPE_CABLE) {
        const input = findItem(this.inputSlices, item);
        if (input) {
          style += this.getBackgroundColor(input.cable.color);
        }
      } else if (
          item.type === CEO_ITEM_TYPE_BALANCED_SPLITTER ||
          item.type === CEO_ITEM_TYPE_UNBALANCED_SPLITTER ||
          item.type === CEO_ITEM_TYPE_CUSTOM
      ) {
        style += this.getBackgroundColor('transparent');
      }
      return style;
    },
    getBackgroundColor(color) {
      return `background-color: ${color};`
    },
    getGroupStyle(item, i) {
      let style = '';
      if (item.type === CEO_ITEM_TYPE_CABLE) {
        style = this.getBackgroundColor(getColorGroupFiber(i));
        style += `width: ${24 * FIBER_WIDTH}px;`;
      } else if (
          item.type === CEO_ITEM_TYPE_BALANCED_SPLITTER ||
          item.type === CEO_ITEM_TYPE_UNBALANCED_SPLITTER ||
          item.type === CEO_ITEM_TYPE_CUSTOM) {
        if (i === 1) {
          style = this.getBackgroundColor(COLOR_DARK);
        } else if (i === 2) {
          style = this.getBackgroundColor(COLOR_DARK);
        }
      }
      return style;
    },
    getFiberStyle(item, i, j) {
      let style = ``;
      if (item.type === CEO_ITEM_TYPE_CABLE) {
        style += this.getBackgroundColor(getColorFiber(j));
      } else if (item.type === CEO_ITEM_TYPE_BALANCED_SPLITTER || item.type === CEO_ITEM_TYPE_CUSTOM) {
        style += this.getBackgroundColor(COLOR_DARK);
      } else if (item.type === CEO_ITEM_TYPE_UNBALANCED_SPLITTER) {
        if (i === 2) {
          style += this.getBackgroundColor(getColorFiberSplitterUnbalanced(j));
        } else {
          style += this.getBackgroundColor(COLOR_DARK);
        }
      }
      style += `height: ${FIBER_HEIGHT}px;`;
      style += `width: ${FIBER_WIDTH}px;`;
      return style;
    },
    getFiberElementId(item, i, j) {
      return `${item.id}-${i}-${j}`;
    },
    getFibersFromGroup(item, i) {
      if (item.type === CEO_ITEM_TYPE_CABLE) {
        const input = findItem(this.inputSlices, item);
        if (input) {
          return input.cable.fibers % 12 === 0 ? 12 : input.cable.fibers - ((i - 1) * 12);
        }
        return 0;
      } else if (item.type === CEO_ITEM_TYPE_BALANCED_SPLITTER || item.type === CEO_ITEM_TYPE_UNBALANCED_SPLITTER) {
        if (i === 1) {
          return 1;
        } else {
          if (item.type === CEO_ITEM_TYPE_BALANCED_SPLITTER) {
            return item.slice;
          } else if (item.type === CEO_ITEM_TYPE_UNBALANCED_SPLITTER) {
            return 2;
          }
        }
      } else if (item.type === CEO_ITEM_TYPE_CUSTOM) {
        return item.cable;
      }
      return 1;
    },
    getNumbersOfGroups(item) {
      if (item.type === CEO_ITEM_TYPE_CABLE) {
        const input = findItem(this.inputSlices, item);
        if (input) {
          return Math.ceil(input.cable.fibers / 12);
        }
        return 0;
      } else if (item.type === CEO_ITEM_TYPE_BALANCED_SPLITTER || item.type === CEO_ITEM_TYPE_UNBALANCED_SPLITTER) {
        return 2;
      }
      return 1;
    },
    getDescriptionHeader(item) {
      if (item.type === CEO_ITEM_TYPE_CABLE) {
        const input = findItem(this.inputSlices, item);
        if (input) {
          return this.infoFromNotes(input.slice.notes);
        }
      } else if (item.type === CEO_ITEM_TYPE_BALANCED_SPLITTER) {
        return `${item.cable}x${item.slice}`;
      } else if (item.type === CEO_ITEM_TYPE_UNBALANCED_SPLITTER) {
        return `1x2 (${item.cable}/${item.slice})`;
      } else if (item.type === CEO_ITEM_TYPE_CUSTOM) {
        return `${item.cable} saídas`;
      }
      return '';
    },
    getHeaderLabel(item) {
      if (item.type === CEO_ITEM_TYPE_CABLE) {
        const input = findItem(this.inputSlices, item);
        if (input) {
          let sliceShowName = input.cable.name;
          if (this.showIdSlice) {
            sliceShowName = `${sliceShowName} ${input.slice.id}`;
          }
          return sliceShowName;
        }
      } else if (item.type === CEO_ITEM_TYPE_BALANCED_SPLITTER || item.type === CEO_ITEM_TYPE_UNBALANCED_SPLITTER) {
        return SPLITTER_NAME;
      } else if (item.type === CEO_ITEM_TYPE_CUSTOM) {
        return item.name || 'Sem nome';
      }
      return '';
    },
    sort(toLeft) {
      this.$emit('sort', this.item, toLeft);
    },
    deleteItem() {
      this.$emit('delete', this.item);
    },
    fiberConnect(id) {
      this.$emit('fiber-connect', id);
    }
  }
}
</script>

<style scoped>
.justify-center {
  justify-content: center;
}

.align-center {
  align-content: center;
}

.fibers {
  margin-left: 2px;
  margin-right: 2px;
}

.fiber-header {
  height: 55px;
}

.cable-header {
  display: flex;
  align-items: center;
  justify-content: center;
}

.group-fibers {
  height: 10px;
  color: black;
  font-size: 8px;
  border-collapse: collapse;
}

.dot {
  cursor: pointer;
  border-radius: 50%;
  padding: 1px;
  display: flex;
  justify-content: center;
}
</style>
