<template>
  <v-data-table :headers="headers" :items="projects">
    <template v-slot:item.user_name="{ item }">
      {{ item.user_name || 'Você' }}
    </template>
    <template v-slot:item.created_at="{ item }">
      {{ item.created_at | dateFormat }}
    </template>
    <template v-slot:item.updated_at="{ item }">
      {{ item.updated_at | dateFormat }}
    </template>
    <template v-slot:item.action="{ item }">
      <v-btn small text @click="openProject(item.id)">
        <v-icon class="mr-3" color="primary">
          mdi-chevron-right
        </v-icon>
        Abrir
      </v-btn>
    </template>
    <template v-slot:no-data>
      <div>Não há projetos</div>
    </template>
    <template v-slot:no-results>
      <div>Nenhum projeto encontrado</div>
    </template>
  </v-data-table>
</template>

<script>
export default {
  props: {
    headers: Array,
    projects: Array
  },
  name: 'ProjectsTab',
  filters: {
    dateFormat: function (value) {
      return new Date(value).toLocaleString();
    }
  },
  computed: {
    isDarkMode() {
      return this.$vuetify.theme.dark;
    }
  },
  methods: {
    openProject(id) {
      this.$emit('open-project', id);
    }
  }
}
</script>
