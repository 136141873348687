<template>
  <dialog-form v-model="dialog" :max-width="maxWidth" @ok="ok">
    <div slot="fields">
      <v-container v-if="connection">
        <v-row no-gutters>
          <v-col cols="12">
            <v-color-picker class="mx-auto" v-model="connection.color" dot-size="25" mode="hexa"/>
            <v-text-field
                v-model.number="connection.height"
                label="Altura"
                :rules="rulesHeight"
                :min="minConnectionHeight"
                :max="maxConnectionHeight"
                type="number"
            />
          </v-col>
        </v-row>
      </v-container>
    </div>
  </dialog-form>
</template>

<script>
import DialogForm from '@/components/DialogForm';
import {copy} from '@/lib/util';
import {getCEOConnectionHeight} from '@/lib/validation/validation';
import {
  MAX_FIBER_SPLICE_BOX_HEIGHT_CONNECTION,
  MIN_FIBER_SPLICE_BOX_HEIGHT_CONNECTION
} from '@/lib/validation/constants';

const INVALID_HEIGHT = 'Altura inválida';

export default {
  name: 'ConnectionProperties',
  components: {DialogForm},
  data() {
    return {
      refConnection: null,
      maxWidth: '400',
      dialog: false,
      connection: null,
      rulesHeight: [
        v => getCEOConnectionHeight(v) !== null || INVALID_HEIGHT
      ],
      minConnectionHeight: MIN_FIBER_SPLICE_BOX_HEIGHT_CONNECTION,
      maxConnectionHeight: MAX_FIBER_SPLICE_BOX_HEIGHT_CONNECTION
    }
  },
  methods: {
    open(connection) {
      this.refConnection = connection;
      this.connection = copy(connection, true);
      this.dialog = true;
    },
    ok() {
      this.$emit('output', this.connection, this.refConnection);
      this.dialog = false;
    }
  }
}
</script>
