<template>
  <l-marker :icon="icon" :lat-lng.sync="coordinates.coordinates" :draggable="draggable" @click="click">
    <item-tool-tip :parent="point" :item="coordinates"/>
  </l-marker>
</template>

<script>
import L from 'leaflet';
import {LMarker} from 'vue2-leaflet';
import ItemToolTip from "@/views/project/ItemToolTip";

export default {
  props: {
    point: Object,
    coordinates: Object,
    draggable: Boolean,
    showLabel: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  components: {
    ItemToolTip,
    LMarker
  },
  name: 'PointCoordinates',
  methods: {
    click() {
      this.$emit('point-click');
    },
    getIconHtml(point) {
      if (this.showLabel) {
        return `
        <div class="point point-label" style="background-color: ${this.point.color}; color: ${this.point.color};">
            ${this.point.name} ${this.coordinates.name || this.coordinates.id}
        </div>
        `;
      }

      return `<div class="point" style="background-color: ${point.color}"/>`;
    }
  },
  computed: {
    icon() {
      return L.divIcon({
        iconSize: [this.point.length, this.point.length],
        html: this.getIconHtml(this.point),
        className: ''
      })
    }
  }
}
</script>

<style>
.point {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}

.point-label {
  padding-top: 80%;
  white-space: nowrap;
  font-size: 20px;
}
</style>
