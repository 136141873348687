<template>
  <handler title="Ocorreu um erro" subtitle="Tente novamente mais tarde"/>
</template>

<script>
import Handler from '@/views/handlers/Handler';

export default {
  name: 'Error',
  components: {Handler}
}
</script>
