<template>
  <v-dialog v-model="dialog" :max-width="maxWidth" persistent>
    <v-card flat outlined>
      <v-card-title/>
      <v-card-text>
        <v-data-table :items="items" :headers="headers" dense>
          <template v-slot:item.name="{ item }">
            <v-btn color="primary" @click="selectItem(item)" text>
              <span class="mr-2 point-badge" :style="getBadgeStyle(item)"/>
              {{ item.name }}
            </v-btn>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon class="mr-1" :color="isDarkMode ? '' : 'primary'" small @click="editItem(item)">mdi-pencil</v-icon>
            <v-icon :color="isDarkMode ? '' : 'primary'" small @click="removeItem(item)">mdi-delete</v-icon>
          </template>
          <template v-slot:no-data>
            <div>Não há item</div>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <slot name="left-actions"/>
        <v-spacer/>
        <v-btn color="primary" text @click="dialog = false">Voltar</v-btn>
        <v-btn color="primary" text @click="createItem">
          Novo
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapActions} from 'vuex';

const CONFIRM = 'Confirmar?';
const REMOVE_MESSAGE = 'Todos os itens relacionados também serão removidos.';

export default {
  props: {
    value: Number,
    items: Array
  },
  name: 'ItemSelect',
  data() {
    return {
      dialog: false,
      maxWidth: '500',
      headers: [
        {text: 'Nome', value: 'name', align: 'center'},
        {text: 'Ações', value: 'actions', align: 'center', sortable: false}
      ]
    }
  },
  computed: {
    isDarkMode() {
      return this.$vuetify.theme.dark;
    }
  },
  methods: {
    ...mapActions('confirmation', ['showConfirmation']),
    open() {
      this.dialog = true;
    },
    createItem() {
      this.$emit('open-properties');
    },
    selectItem(item) {
      this.$emit('item-click');
      this.$emit('input', item.id);
      this.dialog = false;
    },
    editItem(item) {
      this.$emit('open-properties', item);
    },
    removeItem(item) {
      this.showConfirmation({
        title: CONFIRM,
        text: REMOVE_MESSAGE,
        action: () => {
          this.$emit('remove', item);
        }
      })
    },
    getBadgeStyle(item) {
      return `
      background-color: ${item.color};
      border: 2px solid ${this.$vuetify.theme.currentTheme.primary};
      `;
    }
  }
}
</script>

<style>
.point-badge {
  height: 20px;
  width: 20px;
  border-radius: 50%;
}
</style>
