var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.projects},scopedSlots:_vm._u([{key:"item.user_name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.user_name || 'Você')+" ")]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.created_at))+" ")]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.updated_at))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","text":""},on:{"click":function($event){return _vm.openProject(item.id)}}},[_c('v-icon',{staticClass:"mr-3",attrs:{"color":"primary"}},[_vm._v(" mdi-chevron-right ")]),_vm._v(" Abrir ")],1)]}},{key:"no-data",fn:function(){return [_c('div',[_vm._v("Não há projetos")])]},proxy:true},{key:"no-results",fn:function(){return [_c('div',[_vm._v("Nenhum projeto encontrado")])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }