export const MIN_PASSWORD_LENGTH = 6;
export const MAX_PROJECT_NAME_LENGTH = 30;
export const MAX_EMAIL_LENGTH = 320;
export const MAX_PASSWORD_LENGTH = 100;
export const MAX_NAME_USER_LENGTH = 255;
export const MAX_ZOOM = 22;
export const MAX_POINT_LENGTH = 30;
export const MAX_CABLE_NAME = 30;
export const MAX_POINT_NAME = 30;
export const MAX_CUSTOM_EQUIPMENT_NAME = 20;
export const MAX_FIBER_QUANTITY = 144;
export const MAX_CABLE_WEIGHT = 30;
export const MAX_CABLE_WEIGHT_CALCULATE_FIBERS = 10;
export const MAX_CABLE_OFFSET = 20;
export const MAX_NOTES_LENGTH = 255;
export const MAX_POINT_COORDINATE_NAME_LENGTH = 20;
export const MAX_FIBER_SPLICE_BOX_HEIGHT_CONNECTION = 500;
export const MAX_SPLITTER_OUTPUTS = 128;
export const MAX_SPLITTER_FACTOR = 45;
export const MAX_CUSTOM_EQUIPMENT_OUTPUTS = 200;
export const MIN_CABLE_OFFSET = -20;
export const MIN_POINT_LENGTH = 1;
export const MIN_FIBER_QUANTITY = 1;
export const MIN_SPLITTER_OUTPUTS = 2;
export const MIN_SPLITTER_FACTOR = 1;
export const MIN_CUSTOM_EQUIPMENT_OUTPUTS = 2;
export const MIN_FIBER_SPLICE_BOX_HEIGHT_CONNECTION = 17;
export const MIN_CABLE_WEIGHT = 1;
export const DEFAULT_COLOR = '#000000';
export const DEFAULT_LAT = -7.634775927004439;
export const DEFAULT_LNG = -41.75903320312501;
export const DEFAULT_ZOOM = 6;
export const DEFAULT_CABLE_WEIGHT = 3;
export const DEFAULT_CABLE_EMPTY_WEIGHT = '';
export const DEFAULT_CABLE_NAME = 'CABO';
export const DEFAULT_POINT_NAME = 'PONTO';
export const DEFAULT_POINT_LENGTH = 15;
export const DEFAULT_CABLE_OFFSET = 0;
export const DEFAULT_POINT_COORDINATE_RESERVE = 0;
export const DEFAULT_NOTES = '';
export const DEFAULT_SLICE_OFFSET = '';
export const DEFAULT_POINT_COORDINATE_NAME = '';
export const RULER_ID = 'ruler';
export const RULER_POINT_NAME = 'Régua';
export const RULER_CABLE_NAME = 'Régua';
