<template>
  <v-dialog v-model="dialog" max-width="200">
    <v-list>
      <v-list-item link @click="edit">
        Editar
      </v-list-item>
      <v-list-item link @click="remove">
        Remover
      </v-list-item>
    </v-list>
  </v-dialog>
</template>

<script>
export default {
  name: 'SpliceBoxItemMenuContext',
  data() {
    return {
      dialog: false,
      connection: null
    }
  },
  methods: {
    open(connection) {
      this.connection = connection;
      this.dialog = true;
    },
    edit() {
      this.$emit('edit', this.connection);
      this.dialog = false;
    },
    remove() {
      this.$emit('remove', this.connection);
      this.dialog = false;
    }
  }
}
</script>
