<template>
  <l-polyline
      v-if="haveToDraw"
      :lat-lngs="[coordinates1.coordinates, coordinates2.coordinates]"
      :color="cable.color"
      :weight="weight"
      :options="options"
      @click="click">
    <item-tool-tip :parent="cable" :item="slice" :coordinates1="coordinates1" :coordinates2="coordinates2"/>
  </l-polyline>
</template>

<script>
import {LPolyline} from 'vue2-leaflet';
import {getCableWeight} from '@/models/cable';
import ItemToolTip from '@/views/project/ItemToolTip';

export default {
  props: {
    cable: Object,
    slice: Object,
    coordinates1: Object,
    coordinates2: Object
  },
  components: {ItemToolTip, LPolyline},
  name: 'CableSlice',
  methods: {
    click() {
      this.$emit('cable-click');
    }
  },
  computed: {
    options() {
      return {
        offset: typeof this.slice.offset === 'number' ? this.slice.offset : this.cable.offset
      }
    },
    haveToDraw() {
      return !!this.coordinates1 && !!this.coordinates2;
    },
    weight() {
      return getCableWeight(this.cable, this.slice);
    }
  }
}
</script>
