import {
    DEFAULT_CABLE_EMPTY_WEIGHT,
    DEFAULT_CABLE_NAME,
    DEFAULT_CABLE_OFFSET,
    DEFAULT_CABLE_WEIGHT,
    DEFAULT_COLOR,
    DEFAULT_NOTES,
    DEFAULT_SLICE_OFFSET,
    MAX_CABLE_WEIGHT_CALCULATE_FIBERS,
    MAX_FIBER_QUANTITY,
    MIN_FIBER_QUANTITY
} from '@/lib/validation/constants';
import {updateObject} from '@/lib/util';

export const CABLE_RULER = 1;
export const CABLE_DROP = 2;
export const CABLE_DROP_FIGURE_8 = 3;
export const CABLE_AS = 4;

export const getNewCable = (fields, array) => {
    return updateObject({
        id: 0,
        color: DEFAULT_COLOR,
        name: DEFAULT_CABLE_NAME,
        type: CABLE_DROP,
        fibers: MIN_FIBER_QUANTITY,
        offset: DEFAULT_CABLE_OFFSET,
        weight: DEFAULT_CABLE_EMPTY_WEIGHT,
        slices: []
    }, fields, array);
};

export const getNewSliceCable = (fields, array) => {
    return updateObject({
        id: 0,
        point1: 0,
        coordinates1: 0,
        point2: 0,
        coordinates2: 0,
        notes: DEFAULT_NOTES,
        offset: DEFAULT_SLICE_OFFSET,
        weight: DEFAULT_CABLE_EMPTY_WEIGHT
    }, fields, array);
};

export const cablesDescription = [
    {
        type: CABLE_DROP,
        description: 'CABO DROP'
    },
    {
        type: CABLE_DROP_FIGURE_8,
        description: 'CABO DROP FIGURA 8'
    },
    {
        type: CABLE_AS,
        description: 'CABO AS'
    }
];

export const colorFibers = [
    '#008000',
    '#FFFF00',
    '#FFFFFF',
    '#0000FF',
    '#FF0000',
    '#9400D3',
    '#8B4513',
    '#FF1493',
    '#000000',
    '#808080',
    '#FF8C00',
    '#00FFFF',
];

export const colorGroupFibers = [
    '#008000',
    '#FFFF00',
    '#FFFFFF',
];

export const getCableWeight = (cable, slice) => {
    if (slice && typeof slice.weight === 'number') return slice.weight;
    if (cable && cable.type !== CABLE_RULER) {
        if (typeof cable.weight === 'string') {
            return Math.ceil((cable.fibers - 1) / (MAX_FIBER_QUANTITY - 1) * (MAX_CABLE_WEIGHT_CALCULATE_FIBERS - 1) + 1);
        }
        if (typeof cable.weight === 'number') return cable.weight;
    }
    return DEFAULT_CABLE_WEIGHT;
};

export const getColorFiber = (i) => {
    if (i <= 0) return DEFAULT_COLOR;
    return colorFibers[(i - 1) % colorFibers.length];
};

export const getColorGroupFiber = (i) => {
    if (i <= 0 || i >= colorGroupFibers.length) return colorGroupFibers.last();
    return colorGroupFibers[i - 1];
};

export const getColorFiberSplitterUnbalanced = (i) => {
    if (i === 1) return '#FF0000';
    if (i === 2) return '#0000FF';
    return DEFAULT_COLOR;
}
