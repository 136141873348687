<template>
  <handler title="Atualização disponível" subtitle="Atualize a página (CRTL+SHIFT+R) para obter a nova versão"/>
</template>

<script>
import Handler from '@/views/handlers/Handler';

export default {
  name: 'Update',
  components: {Handler}
}
</script>
