<template>
  <dialog-form v-model="dialog" :max-width="maxWidth" :dialog-label="label" @ok="ok">
    <div slot="fields">
      <v-container class="pa-0" v-if="item">
        <v-row no-gutters>
          <v-col cols="12">
            <v-select
                v-model="type"
                label="Tipo"
                :items="parentItems"
                item-text="name"
                item-value="id"
            />
            <v-text-field
                v-if="item.name !== undefined"
                v-model="item.name"
                label="Nome"
                :maxLength="maxPointCoordinateNameLength"
                :counter="maxPointCoordinateNameLength"
                :rules="rulesName"
            />
            <v-text-field
                v-if="item.offset !== undefined"
                v-model.number="item.offset"
                label="Offset do trecho"
                :min="minCableOffset"
                :max="maxCableOffset"
                type="number"
                :rules="rulesSliceOffset"
            />
            <v-text-field
                v-if="item.weight !== undefined"
                v-model.number="item.weight"
                label="Espessura do trecho"
                :min="minCableWeight"
                :max="maxCableWeight"
                type="number"
                :rules="rulesSliceWeight"
            />
            <v-text-field
                v-if="item.reserve !== undefined"
                v-model.number="item.reserve"
                label="Reserva (m)"
                min="0"
                type="number"
                :rules="rulesCoordinatesReserve"
            />
            <v-textarea
                v-model="item.notes"
                label="Anotações (HTML)"
                :maxLength="maxNotesLength"
                :counter="maxNotesLength"
                :rules="rulesNotes"
            />
          </v-col>
        </v-row>
      </v-container>
    </div>
  </dialog-form>
</template>

<script>
import {
  MAX_CABLE_OFFSET,
  MAX_CABLE_WEIGHT,
  MAX_NOTES_LENGTH,
  MAX_POINT_COORDINATE_NAME_LENGTH,
  MIN_CABLE_OFFSET, MIN_CABLE_WEIGHT,
} from '@/lib/validation/constants';
import DialogForm from '@/components/DialogForm';
import {
  getNotes,
  getPointCoordinateName,
  getSliceOffset,
  parseIntegerNonNegative
} from '@/lib/validation/validation';
import {copy} from '@/lib/util';
import {rulesSliceWeight} from "@/lib/validation/rules";

const INVALID_NAME = 'Nome inválido';
const INVALID_NOTES = 'Anotações inválidas';
const INVALID_RESERVE = 'Reserva inválida';
const INVALID_OFFSET = 'Offset inválido';

export default {
  name: 'SubItemProperties',
  components: {DialogForm},
  data() {
    return {
      minCableWeight: MIN_CABLE_WEIGHT,
      maxCableWeight: MAX_CABLE_WEIGHT,
      minCableOffset: MIN_CABLE_OFFSET,
      maxCableOffset: MAX_CABLE_OFFSET,
      parentItems: [],
      parentItem: null,
      type: 0,
      maxNotesLength: MAX_NOTES_LENGTH,
      maxPointCoordinateNameLength: MAX_POINT_COORDINATE_NAME_LENGTH,
      maxWidth: '400',
      dialog: false,
      refItem: null,
      item: null,
      rulesName: [
        v => getPointCoordinateName(v) !== null || INVALID_NAME
      ],
      rulesNotes: [
        v => getNotes(v) !== null || INVALID_NOTES
      ],
      rulesCoordinatesReserve: [
        v => parseIntegerNonNegative(v) !== null || INVALID_RESERVE
      ],
      rulesSliceOffset: [
        v => getSliceOffset(v) !== null || INVALID_OFFSET
      ],
      rulesSliceWeight: rulesSliceWeight
    }
  },
  methods: {
    open(item, parentItem = null, parentItems = []) {
      this.parentItem = parentItem;
      this.type = parentItem ? parentItem.id : 0;
      this.parentItems = parentItems;
      this.refItem = item;
      this.item = copy(item, true);
      this.dialog = true;
    },
    ok() {
      this.$emit('output', {
        item: this.item,
        refItem: this.refItem,
        type: this.type,
        parentItem: this.parentItem
      });
      this.dialog = false;
    }
  },
  computed: {
    label() {
      return this.item && this.parentItem ? `${this.parentItem.name} ${this.item.name || this.item.id}` : '';
    }
  }
}
</script>
