<template>
  <dialog-form v-model="dialog"
               :dialog-label="label"
               @ok="output">
    <div v-if="project" slot="fields">
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-text-field
                ref="projectName"
                v-model="project.name"
                label="Nome"
                :rules="projectNameRules"
                :maxLength="maxProjectNameLength"
                validate-on-blur
            />
          </v-col>
        </v-row>
      </v-container>
    </div>
  </dialog-form>
</template>

<script>
import {copy} from '@/lib/util';
import DialogForm from '@/components/DialogForm';
import {MAX_PROJECT_NAME_LENGTH} from '@/lib/validation/constants';

export default {
  components: {DialogForm},
  props: {
    label: String
  },
  name: 'ProjectProperties',
  data() {
    return {
      refProject: null,
      dialog: false,
      project: null,
      maxProjectNameLength: MAX_PROJECT_NAME_LENGTH,
      projectNameRules: [
        v => typeof v === 'string' && v.length > 0 && v.length <= MAX_PROJECT_NAME_LENGTH || 'Este campo é obrigatório'
      ]
    }
  },
  methods: {
    open(project) {
      this.refProject = project;
      this.project = copy(project, true);
      this.dialog = true;
      setTimeout(() => {
        this.$refs.projectName.focus();
      }, 100);
    },
    output() {
      this.$emit('output', this.project, this.refProject);
      this.dialog = false;
    }
  }
}
</script>
