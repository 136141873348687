<template>
  <v-dialog v-model="dialog" persistent eager fullscreen hide-overlay transition="dialog-bottom-transition"
            @keydown.esc="resetFibers">
    <v-card id="card" v-if="dialog">
      <connection-properties ref="connectionProperties" @output="outputConnection"/>
      <splice-box-item-properties ref="spliceBoxItemProperties" @output="outputItem"/>
      <splice-box-item-menu-context ref="spliceBoxItemMenuContext" @edit="editConnection" @remove="removeConnection"/>

      <v-toolbar :color="isDarkMode ? '' : 'primary'" flat dark style="z-index: 1;">
        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title v-if="point && coordinates">
          {{ `${point.name} ${coordinates.name || coordinates.id}` }}
        </v-toolbar-title>
        <v-spacer/>
        <v-btn v-if="!readOnly" text @click="save">
          <v-icon class="mr-3">mdi-content-save</v-icon>
          Salvar
        </v-btn>
      </v-toolbar>

      <v-card v-show="messages.length" class="message pa-2">
        <v-card-text>
          <div :key="i" v-for="(m, i) in messages">{{ m }}</div>
        </v-card-text>
      </v-card>

      <div id="canvas" :style="canvasStyle">
        <div id="zoom" class="fill-height">
          <div class="horizontal-list">
            <div>
              <div class="horizontal-list text-center text-no-wrap">
                <splice-box-item :key="item.id" v-for="item in ceo.items" :item="item" :input-slices="inputSlices"
                                 :fiber1="fiber1"
                                 :dark="dark"
                                 :read-only="readOnly"
                                 :show-id-slice="showIdSlice"
                                 @delete="deleteItem"
                                 @sort="sort"
                                 @fiber-connect="fiberConnect"/>
              </div>
              <div :key="updateConnections">
                <div :key="c.id" v-for="c in ceo.connections" class="connection" :style="getConnectionStyle(c)"/>
              </div>
            </div>
          </div>
        </div>
      </div>

      <v-footer class="pt-2 pb-2" color="transparent" fixed padless>
        <v-spacer/>

        <div class="pr-3" :key="item.id" v-for="item in footerToolbarItems">
          <v-tooltip v-if="!item.onlyEditMode || !readOnly" top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" small :color="isDarkMode ? '' : 'primary'" dark fab @click="item.action">
                <v-icon>{{ item.icon }}</v-icon>
              </v-btn>
            </template>
            <span>{{ item.description }}</span>
          </v-tooltip>
        </div>

        <v-spacer/>
      </v-footer>
    </v-card>
  </v-dialog>
</template>

<script>
import {getColorFiber} from '@/models/cable';
import {copy, updateObject} from '@/lib/util';
import {
  CEO_ITEM_TYPE_CABLE,
  getNewPointCEOConnection,
  getNewPointCoordinatesCEO,
  getNewPointCEOItem,
  CEO_ITEM_TYPE_BALANCED_SPLITTER,
} from '@/models/point';
import {
  DEFAULT_COLOR,
  MAX_FIBER_SPLICE_BOX_HEIGHT_CONNECTION,
  MIN_FIBER_SPLICE_BOX_HEIGHT_CONNECTION
} from '@/lib/validation/constants';
import ConnectionProperties from '@/views/project/ConnectionProperties';
import Panzoom from '@panzoom/panzoom';
import {parseIntegerPositive} from '@/lib/validation/validation';
import SpliceBoxItemProperties from '@/views/project/SpliceBoxItemProperties';
import SpliceBoxItemMenuContext from '@/views/project/SpliceBoxItemMenuContext';
import {FIBER_WIDTH, findItem, isCable} from '@/lib/spliceBox';
import SpliceBoxItem from '@/views/project/SpliceBoxItem';

const MESSAGE_CLICK_TO_CONNECT = 'Clique na próxima fibra para realizar a conexão';
const MESSAGE_SAME_FIBER = 'Clique na mesma fibra para cancelar a conexão atual';
const FIBER_NEW_HEIGHT_FACTOR = 9;

export default {
  components: {
    SpliceBoxItem,
    SpliceBoxItemMenuContext,
    SpliceBoxItemProperties,
    ConnectionProperties
  },
  props: {
    cables: Array,
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  name: 'SpliceBox',
  data() {
    return {
      showIdSlice: false,
      dark: true,
      dialog: false,
      point: null,
      coordinates: null,
      ceo: getNewPointCoordinatesCEO(),
      inputSlices: [],
      messages: [],
      fiber1: null,
      fiber2: null,
      updateConnections: 0,
      panzoomOptions: {
        animate: true,
        canvas: true,
        maxScale: 100,
        minScale: 0,
        step: 0.6
      }
    }
  },
  computed: {
    canvasStyle() {
      return this.dark ?
          {
            'background-color': '#1E1E1E',
            '--opacity': '0.1'
          } : {
            'background-color': '#DEDEDE',
            '--opacity': '0.7'
          };
    },
    isDarkMode() {
      return this.$vuetify.theme.dark;
    },
    footerToolbarItems() {
      return [
        {
          id: 1,
          icon: 'mdi-plus',
          description: 'Novo equipamento',
          onlyEditMode: true,
          action: () => {
            this.openItemProperties();
          }
        },
        {
          id: 2,
          icon: 'mdi-palette',
          description: 'Mudar tema',
          onlyEditMode: false,
          action: () => {
            this.dark = !this.dark;
          }
        },
        {
          id: 3,
          icon: 'mdi-eye',
          description: 'Visualizar ID de cabos',
          onlyEditMode: false,
          action: () => {
            this.showIdSlice = !this.showIdSlice;
            this.redraw();
          }
        }
      ]
    }
  },
  methods: {
    startPanzoom() {
      let element = document.querySelector('#zoom');
      const panzoom = Panzoom(element, this.panzoomOptions);
      element.parentElement.addEventListener('wheel', function (event) {
        panzoom.zoomWithWheel(event)
      });
    },
    redraw() {
      this.$nextTick(() => {
        this.updateConnections++;
      });
    },
    sort(item, toLeft) {
      const items = this.ceo.items;
      const index = items.findIndex(i => i.id === item.id);
      const left = index > 0 && toLeft;
      const right = index < (items.length - 1) && !toLeft;
      if (left === right) return;
      const array = copy(items);
      array.splice(index, 1);
      array.splice(left ? index - 1 : index + 1, 0, item);
      this.ceo.items = array;
      this.redraw();
    },
    getSortFibers(f1, f2) {
      let fiber1 = document.getElementById(f1);
      let fiber2 = document.getElementById(f2);

      if (fiber1 && fiber2) {
        if (fiber2.offsetLeft < fiber1.offsetLeft) {
          let aux = fiber2;
          fiber2 = fiber1;
          fiber1 = aux;
        }
      }

      return {fiber1, fiber2};
    },
    getConnectionStyle(connection) {
      if (!connection) return '';
      const {fiber1, fiber2} = this.getSortFibers(connection.fiber1, connection.fiber2);
      if (!fiber1 || !fiber2) return '';
      return `
        left: ${fiber1.offsetLeft}px;
        height: ${connection.height}px;
        width: ${fiber2.offsetLeft - fiber1.offsetLeft + FIBER_WIDTH}px;
        margin-top: -${FIBER_WIDTH}px;
        border-left: solid ${FIBER_WIDTH}px ${connection.color};
        border-right: solid ${FIBER_WIDTH}px ${connection.color};
        border-bottom: solid ${FIBER_WIDTH}px ${connection.color};
      `;
    },
    getCurrentInputSlices(point, coordinates) {
      const inputSlices = [];
      this.cables.map(c => {
        c.slices.map(s => {
          if (s.point1 === point.id && s.coordinates1 === coordinates.id || s.point2 === point.id && s.coordinates2 === coordinates.id) {
            inputSlices.push({
              cable: c,
              slice: s
            });
          }
        });
      });
      return inputSlices;
    },
    open(point, coordinates) {
      if (!point || !coordinates) return;
      this.resetFibers();
      this.ceo = getNewPointCoordinatesCEO();
      this.point = point;
      this.coordinates = coordinates;
      this.startComponents(point, coordinates);
    },
    startComponents(point, coordinates) {
      let ceo = null;
      if (coordinates.ceo) {
        ceo = copy(coordinates.ceo);
      }
      this.inputSlices = this.getCurrentInputSlices(point, coordinates);

      let items = [];
      if (ceo && ceo.items && ceo.items.length > 0) {
        items = ceo.items.filter(i => i.type !== CEO_ITEM_TYPE_CABLE || (i.type === CEO_ITEM_TYPE_CABLE && findItem(this.inputSlices, i)));
      }
      this.inputSlices.map(i => {
        if (!findItem(items, i)) {
          items.push(getNewPointCEOItem({
            id: items.newId(),
            type: CEO_ITEM_TYPE_CABLE,
            cable: i.cable.id,
            slice: i.slice.id
          }));
        }
      });
      this.ceo.items = items;
      this.dialog = true;
      this.$nextTick(() => {
        if (ceo && ceo.connections && ceo.connections.length > 0) {
          this.ceo.connections = ceo.connections.filter(c => document.getElementById(c.fiber1) && document.getElementById(c.fiber2));
        }
        this.startPanzoom();
      });
    },
    save() {
      if (this.readOnly) return;
      this.$emit('save', this.coordinates, this.ceo);
      this.dialog = false;
    },
    resetFibers() {
      if (this.readOnly) return;
      this.messages = [];
      this.fiber1 = null;
      this.fiber2 = null;
    },
    fiberConnect(fiber) {
      if (this.readOnly) return;
      if (this.fiber1 === fiber) {
        return this.resetFibers();
      }
      const connection = this.ceo.connections.find(c => c.fiber1 === fiber || c.fiber2 === fiber);
      if (connection) {
        return this.$refs.spliceBoxItemMenuContext.open(connection);
      }
      if (this.fiber1) {
        this.fiber2 = fiber;
        this.makeConnection();
      } else {
        this.messages = [MESSAGE_CLICK_TO_CONNECT, MESSAGE_SAME_FIBER];
        this.fiber1 = fiber;
      }
    },
    makeConnection() {
      if (!this.fiber1 || !this.fiber2) return;
      const {fiber1, fiber2} = this.getSortFibers(this.fiber1, this.fiber2);
      if (!fiber1 || !fiber2) return;

      let height = MIN_FIBER_SPLICE_BOX_HEIGHT_CONNECTION;
      const lastConnection = this.ceo.connections.last();
      if (lastConnection) {
        height = lastConnection.height + FIBER_NEW_HEIGHT_FACTOR;
      }
      if (height > MAX_FIBER_SPLICE_BOX_HEIGHT_CONNECTION) {
        height = MAX_FIBER_SPLICE_BOX_HEIGHT_CONNECTION;
      }
      let color = DEFAULT_COLOR;
      if (fiber1.innerText) {
        const fiberNumber = parseIntegerPositive(fiber1.innerText);
        if (fiberNumber) {
          color = getColorFiber(fiberNumber);
        }
      }
      this.ceo.connections.push(getNewPointCEOConnection({
        id: this.ceo.connections.newId(),
        fiber1: fiber1.id,
        fiber2: fiber2.id,
        height: height,
        color: color
      }));

      this.resetFibers();
    },
    editConnection(connection) {
      if (this.readOnly) return;
      this.$refs.connectionProperties.open(connection);
    },
    outputConnection(connection, refConnection) {
      if (this.readOnly) return;
      updateObject(refConnection, connection);
    },
    openItemProperties(item) {
      if (this.readOnly) return;
      if (!item) {
        item = getNewPointCEOItem({
          id: 0,
          type: CEO_ITEM_TYPE_BALANCED_SPLITTER,
          cable: 1, // 1x2 Splitter
          slice: 2 // 1x2 Splitter
        });
      }
      this.$refs.spliceBoxItemProperties.open(item);
    },
    outputItem(item, refItem) {
      if (this.readOnly) return;
      if (!refItem.id) {
        this.ceo.items.push(getNewPointCEOItem(item, this.ceo.items));
      }
      //else update object item
    },
    removeConnection(connection) {
      if (this.readOnly) return;
      this.ceo.connections.remove(connection);
    },
    deleteItem(item) {
      if (this.readOnly) return;
      if (!isCable(item)) {
        const connectionsToDelete = this.ceo.connections.filter(c => c.fiber1.startsWith(`${item.id}-`) || c.fiber2.startsWith(`${item.id}-`));
        connectionsToDelete.map(c => this.removeConnection(c));
        this.ceo.items.remove(item);
        this.redraw();
      }
    }
  }
}
</script>

<style lang="scss">
#canvas {
  position: fixed;
  height: 100%;
  width: 100%;
}

#canvas::after {
  content: "";
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
  background: url('../../assets/splicebox/grid.gif');
  opacity: var(--opacity);
}

.horizontal-list {
  display: flex;
}

.connection {
  position: absolute;
  z-index: -1;
}

.message {
  float: left;
  top: 10px;
  left: 10px;
  z-index: 3;
}
</style>
