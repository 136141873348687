import L from 'leaflet';

export const copy = (obj, removeDeepFields = false) => {
    if (removeDeepFields) {
        const objCopy = {};
        Object.entries(obj).forEach(([key, value]) => {
            if (typeof value === "object" || value instanceof Array) return;
            objCopy[key] = value;
        });
        return objCopy;
    }
    return JSON.parse(JSON.stringify(obj));
}

export const distance = (point1, point2) => {
    const p1 = L.latLng(point1.coordinates);
    const p2 = L.latLng(point2.coordinates);
    return p1.distanceTo(p2);
};

export const labelDistance = (distance) => {
    let measure = 'm';
    if (distance >= 1000) {
        distance = distance / 1000;
        measure = 'km';
    }
    return `${distance.toFixed(2)} ${measure}`;
};

export const updateObject = (obj, fields, array) => {
    if (array) {
        obj.id = array.newId();
    }
    if (fields) {
        Object.entries(fields).forEach(([key, value]) => {
            if (key === 'id' && array) return;
            if (obj[key] !== value) {
                obj[key] = value;
            }
        });
    }
    return obj;
};
