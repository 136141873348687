import {CEO_ITEM_TYPE_CABLE} from '@/models/point';

export const CABLE_HEIGHT = 100;
export const FIBER_WIDTH = 5;
export const FIBER_HEIGHT = 20;

export const isCable = (item) => {
    return item && item.type === CEO_ITEM_TYPE_CABLE;
};

export const findItem = (items, i) => {
    if (i.id) {
        return items.find(item => item.cable.id === i.cable && item.slice.id === i.slice);
    } else {
        return items.find(item => item.cable === i.cable.id && item.slice === i.slice.id);
    }
};
