<template>
  <dialog-form v-model="dialog" :dialog-label="label" :max-width="maxWidth" @ok="ok">
    <div slot="fields">
      <v-container class="pa-0" v-if="item">
        <v-row no-gutters>
          <v-col cols="12">
            <v-color-picker class="mx-auto" v-model="item.color" dot-size="25" mode="hexa"/>
            <v-text-field
                v-model="item.name"
                label="Nome"
                :maxLength="isPoint ? maxPointName : maxCableName"
                :counter="isPoint ? maxPointName : maxCableName"
                :rules="isPoint ? rulesPointName : rulesCableName"
            />
            <v-select
                v-if="item.type !== undefined"
                v-model="item.type"
                label="Tipo do cabo"
                :items="cablesDescription"
                item-value="type"
                item-text="description"
                :rules="rulesCableType"
            />
            <v-text-field
                v-if="item.fibers !== undefined"
                v-model.number="item.fibers"
                label="Quantidade de fibras"
                :min="minFiberQuantity"
                :max="maxFiberQuantity"
                type="number"
                :rules="rulesCableFibers"
            />
            <v-text-field
                v-if="item.offset !== undefined"
                v-model.number="item.offset"
                label="Offset"
                :min="minCableOffset"
                :max="maxCableOffset"
                type="number"
                :rules="rulesCableOffset"
            />
            <v-text-field
                v-if="item.weight !== undefined"
                v-model.number="item.weight"
                label="Espessura do cabo"
                :min="minCableWeight"
                :max="maxCableWeight"
                type="number"
                :rules="rulesSliceWeight"
            />
            <v-text-field
                v-if="item.length !== undefined"
                v-model.number="item.length"
                label="Tamanho"
                :min="minPointLength"
                :max="maxPointLength"
                type="number"
                :rules="rulesPointLength"
            />
          </v-col>
        </v-row>
      </v-container>
    </div>
  </dialog-form>
</template>

<script>
import {
  MAX_CABLE_NAME,
  MAX_CABLE_OFFSET,
  MAX_CABLE_WEIGHT,
  MAX_FIBER_QUANTITY,
  MAX_POINT_LENGTH,
  MAX_POINT_NAME,
  MIN_CABLE_OFFSET,
  MIN_CABLE_WEIGHT,
  MIN_FIBER_QUANTITY,
  MIN_POINT_LENGTH
} from '@/lib/validation/constants';
import {cablesDescription} from '@/models/cable';
import DialogForm from '@/components/DialogForm';
import {
  getCableFibers,
  getCableName,
  getCableOffset,
  getCableType,
  getPointLength,
  getPointName,
} from '@/lib/validation/validation';
import {copy} from '@/lib/util';
import {rulesSliceWeight} from '@/lib/validation/rules';

const INVALID_NAME = 'Nome inválido';
const INVALID_TYPE = 'Tipo de cabo inválido';
const INVALID_QUANTITY = 'Quantidade inválida';
const INVALID_OFFSET = 'Offset inválido';
const INVALID_LENGTH = 'Tamanho inválido';

export default {
  name: 'ItemProperties',
  components: {DialogForm},
  data() {
    return {
      refItem: null,
      cablesDescription: cablesDescription,
      minCableWeight: MIN_CABLE_WEIGHT,
      maxCableWeight: MAX_CABLE_WEIGHT,
      minCableOffset: MIN_CABLE_OFFSET,
      maxCableOffset: MAX_CABLE_OFFSET,
      minFiberQuantity: MIN_FIBER_QUANTITY,
      maxFiberQuantity: MAX_FIBER_QUANTITY,
      maxCableName: MAX_CABLE_NAME,
      maxPointName: MAX_POINT_NAME,
      maxPointLength: MAX_POINT_LENGTH,
      minPointLength: MIN_POINT_LENGTH,
      maxWidth: '400',
      dialog: false,
      item: null,
      rulesCableType: [
        v => getCableType(v) !== null || INVALID_TYPE
      ],
      rulesCableName: [
        v => getCableName(v) !== null || INVALID_NAME
      ],
      rulesCableFibers: [
        v => getCableFibers(v) !== null || INVALID_QUANTITY
      ],
      rulesCableOffset: [
        v => getCableOffset(v) !== null || INVALID_OFFSET
      ],
      rulesPointName: [
        v => getPointName(v) !== null || INVALID_NAME
      ],
      rulesPointLength: [
        v => getPointLength(v) !== null || INVALID_LENGTH
      ],
      rulesSliceWeight: rulesSliceWeight
    }
  },
  computed: {
    label() {
      return this.refItem ? this.refItem.name : '';
    },
    isPoint() {
      return this.item && this.item.length !== undefined;
    }
  },
  methods: {
    open(item) {
      this.refItem = item;
      this.item = copy(item, true);
      this.dialog = true;
    },
    ok() {
      this.$emit('output', this.item, this.refItem);
      this.dialog = false;
    }
  }
}
</script>
