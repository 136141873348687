<template>
  <div v-if="projectJson" class="fill-height" @keydown.esc="option = options.none">
    <sub-item-properties ref="cableSubProperties" @output="outputSlice"/>
    <sub-item-properties ref="pointSubProperties" @output="outputPointCoordinates"/>
    <item-properties ref="cableProperties" @output="outputCable"/>
    <item-properties ref="pointProperties" @output="outputPoint"/>
    <item-select ref="cableSelect" v-model="selectedCableData" :items="projectJson.cables"
                 @open-properties="openPropertiesCable" @remove="removeCable"
                 @item-click="option = options.connect_cable"/>
    <item-select ref="pointSelect" v-model="selectedPointData" :items="projectJson.points"
                 @open-properties="openPropertiesPoint" @remove="removePoint"
                 @item-click="option = options.point_create">
      <v-checkbox slot="left-actions" v-model="useSelectedCable" label="Ponto e cabo"/>
    </item-select>
    <item-menu-context v-model="menuContextCable.dialog" :edit-action="menuContextCable.editAction"
                       :remove-action="menuContextCable.removeAction"/>
    <item-menu-context v-model="menuContextPoint.dialog" :edit-action="menuContextPoint.editAction"
                       :remove-action="menuContextPoint.removeAction" :splices-action="menuContextPoint.splicesAction"
                       splices/>
    <splice-box ref="spliceBox" :cables="projectJson.cables" @save="saveSpliceBox" :read-only="readOnly"/>
    <l-map
        class="fill-height map"
        :style="mapStyle"
        :zoom.sync="projectJson.zoom"
        :center.sync="projectJson.center"
        :options="mapOptions"
        ref="map"
        @ready="mapLoaded">
      <l-control-scale position="bottomright" :imperial="false" :metric="true"/>
      <l-control-layers position="topright"/>
      <l-control position="topright">
        <v-btn title="Edição" v-if="write" @click="readOnly = !readOnly" :color="isDarkMode ? '' : 'white'" icon
               text>
          <v-icon>{{ readOnly ? 'mdi-lock' : 'mdi-lock-open-variant' }}</v-icon>
        </v-btn>
      </l-control>
      <l-control position="topright">
        <v-btn title="Localização em tempo real" @click="locate" :loading="locationLoading"
               :color="isDarkMode ? '' : 'white'" icon text>
          <v-icon>{{ location ? 'mdi-crosshairs-gps' : 'mdi-crosshairs' }}</v-icon>
        </v-btn>
      </l-control>
      <l-control position="topright">
        <v-btn title="Nomes" @click="showLabels" :color="isDarkMode ? '' : 'white'" icon text>
          <v-icon>{{ labels ? 'mdi-text-recognition' : 'mdi-format-text' }}</v-icon>
        </v-btn>
      </l-control>
      <l-control position="topleft">
        <v-card v-show="measuring">
          <v-card-title class="subtitle-2">
            Distância
          </v-card-title>
          <v-card-text>
            <div>{{ totalDistanceMeasuring }}</div>
          </v-card-text>
        </v-card>
      </l-control>
      <l-tile-layer
          :key="tileProvider.id"
          v-for="tileProvider in tileProviders"
          :name="tileProvider.name"
          :visible="tileProvider.visible"
          :url="tileProvider.url"
          :options="tileOptions"
          layer-type="base"/>
      <l-layer-group :visible="measuring">
        <point-coordinates :key="`ruler_point_coordinates_${c.id}`" v-for="c in ruler.point.coordinates"
                           :point="ruler.point"
                           :coordinates="c"
                           draggable
                           @point-click="pointClick(ruler.point, c)"
        />
        <cable-slice :key="`ruler_cable_slice_${s.id}`" v-for="s in ruler.cable.slices"
                     :cable="ruler.cable"
                     :slice="s"
                     :coordinates1="ruler.point.coordinates.findId(s.coordinates1)"
                     :coordinates2="ruler.point.coordinates.findId(s.coordinates2)"
        />
      </l-layer-group>
      <l-layer-group :visible="pointsVisible">
        <div :key="`point_${p.id}`" v-for="p in projectJson.points">
          <point-coordinates
              :key="`point_${p.id}_coordinates_${c.id}`"
              v-for="c in p.coordinates"
              :point="p"
              :coordinates="c"
              :draggable="!readOnly"
              :show-label="labels"
              @point-click="pointClick(p, c)"/>
        </div>
      </l-layer-group>
      <l-layer-group :visible="cablesVisible">
        <l-polyline
            v-if="haveToDrawDynamicCable"
            :lat-lngs="[lastPointClicked.coordinates.coordinates, mousePoint]"
            :weight="selectedCableWeight"
            :color="selectedCableColor"
        />
        <div :key="`cable_${c.id}`" v-for="c in projectJson.cables">
          <cable-slice :key="`cable_${c.id}_slice_${s.id}`" v-for="s in c.slices"
                       :cable="c"
                       :slice="s"
                       :coordinates1="projectJson.points.findId(s.point1).coordinates.findId(s.coordinates1)"
                       :coordinates2="projectJson.points.findId(s.point2).coordinates.findId(s.coordinates2)"
                       @cable-click="cableClick(c, s)"/>
        </div>
      </l-layer-group>
      <l-layer-group>
        <template v-if="location">
          <l-circle :lat-lng="location.latlng" :fillOpacity="1" :radius="0.1"/>
          <l-circle :lat-lng="location.latlng" :radius="location.accuracy/2" :stroke="false"/>
        </template>
      </l-layer-group>
    </l-map>
    <v-footer v-if="!readOnly" height="55" dark fixed padless app :color="isDarkMode ? '' : 'primary'">
      <div class="d-flex text-center" style="width: 100%;">
        <div class="pointer-footer mr-3 ml-3" v-for="item in mapMenu.items" :key="item.id" @click="item.action">
          <v-icon size="24px">{{ item.icon }}</v-icon>
          <div class="caption">{{ item.title }}</div>
        </div>

        <div class="pointer-footer mr-3 ml-3" v-show="option !== options.none" @click="option = options.none">
          <v-icon size="24px">mdi-undo</v-icon>
          <div class="caption">Voltar (Esc)</div>
        </div>

        <v-spacer/>

        <div class="pointer-footer mr-3 ml-3" @click="save">
          <v-icon size="24px">mdi-content-save</v-icon>
          <div class="caption">Salvar</div>
        </div>
      </div>
    </v-footer>
  </div>
</template>

<script>
import 'leaflet/dist/leaflet.css';
import {
  LControl,
  LControlLayers,
  LControlScale,
  LLayerGroup,
  LMap,
  LPolyline,
  LTileLayer,
  LCircle
} from 'vue2-leaflet';
import PointCoordinates from '@/views/project/PointCoordinates';
import CableSlice from '@/views/project/CableSlice';
import {
  DEFAULT_COLOR,
  MAX_ZOOM,
  RULER_CABLE_NAME,
  RULER_ID,
  RULER_POINT_NAME,
} from '@/lib/validation/constants';
import {Icon} from 'leaflet';
import {CABLE_RULER, getCableWeight, getNewCable, getNewSliceCable} from '@/models/cable';
import {getNewPoint, getNewPointCoordinates} from '@/models/point';
import {copy, distance, labelDistance, updateObject} from '@/lib/util';
import ItemSelect from '@/views/project/ItemSelect';
import 'leaflet-polylineoffset';
import {mapActions} from 'vuex';
import ItemProperties from '@/views/project/ItemProperties';
import SubItemProperties from '@/views/project/SubItemProperties';
import ItemMenuContext from '@/views/project/ItemMenuContext';
import SpliceBox from '@/views/project/SpliceBox';

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

const CABLE_SLICE_EXISTS = 'Conexão já existente para este cabo';
const LOCATION_ERROR = 'Não foi possível obter a localização';
const QUIT_MESSAGE = 'Seu projeto ainda não foi salvo. Deseja realmente sair?';
const AUTO_SERVICE_INTERVAL = 10000;

export default {
  props: {
    project: Object,
    write: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  name: 'Map',
  components: {
    SpliceBox,
    ItemMenuContext,
    SubItemProperties,
    ItemSelect,
    ItemProperties,
    CableSlice,
    PointCoordinates,
    LMap,
    LTileLayer,
    LControl,
    LControlLayers,
    LControlScale,
    LPolyline,
    LLayerGroup,
    LCircle
  },
  data() {
    return {
      labels: false,
      autoSaveServiceID: null,
      mapObject: null,
      location: null,
      locationLoading: false,
      readOnly: true,
      menuContextCable: {
        dialog: false,
        editAction: null,
        removeAction: null
      },
      menuContextPoint: {
        dialog: false,
        splicesAction: null,
        editAction: null,
        removeAction: null
      },
      useSelectedCable: false,
      selectedPointData: 0,
      selectedCableData: 0,
      ruler: {
        point: getNewPoint({id: RULER_ID, name: RULER_POINT_NAME}),
        cable: getNewCable({id: RULER_ID, name: RULER_CABLE_NAME, type: CABLE_RULER})
      },
      isFirstCreatePoint: false,
      pointsVisible: true,
      cablesVisible: true,
      options: {
        none: 0,
        point_create: 1,
        connect_cable: 2,
        ruler: 3
      },
      lastPointClicked: null,
      mousePoint: null,
      actions: 0,
      option: 0,
      projectJson: null,
      isProjectSave: true,
      mapOptions: {
        zoomControl: false,
        attributionControl: false
      },
      tileOptions: {
        maxZoom: MAX_ZOOM,
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
      },
      tileProviders: [
        {
          id: 1,
          name: 'Satélite',
          visible: true,
          url: 'https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}'
        },
        {
          id: 2,
          name: 'Satélite e ruas',
          visible: false,
          url: 'https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}'
        },
        {
          id: 3,
          name: 'Ruas',
          visible: false,
          url: 'https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}'
        }
      ],
      mapMenu: {
        items: [
          {
            id: 1,
            title: 'Pontos',
            icon: 'mdi-checkbox-blank-circle',
            action: this.openSelectPoint
          },
          {
            id: 2,
            title: 'Cabos',
            icon: 'mdi-cable-data',
            action: this.openSelectCable
          },
          {
            id: 3,
            title: 'Régua',
            icon: 'mdi-ruler',
            action: () => {
              this.option = this.options.ruler;
            }
          }
        ]
      }
    }
  },
  mounted() {
    this.projectJson = copy(this.project);
    this.startAutoSaveService();
  },
  methods: {
    ...mapActions('alert', ['showAlert']),
    locate() {
      if (this.mapObject && !this.locationLoading) {
        if (this.location) {
          this.location = null;
          this.mapObject.stopLocate();
        } else {
          this.locationLoading = true;
          this.mapObject.locate({watch: true, enableHighAccuracy: true});
        }
      }
    },
    invalidateProject() {
      this.isProjectSave = false;
      window.onbeforeunload = function () {
        return QUIT_MESSAGE;
      }
    },
    startAutoSaveService() {
      if (this.autoSaveServiceID === null) {
        this.autoSaveServiceID = setInterval(() => {
          if (!this.isProjectSave) {
            this.emitSave(false);
          }
        }, AUTO_SERVICE_INTERVAL);
      }
    },
    // sliceExists(cable, p1, c1, p2, c2) {
    //   const slices = cable.slices.filter(
    //       c => (
    //           (c.point1 === p1 && c.coordinates1 === c1 && c.point2 === p2 && c.coordinates2 === c2) ||
    //           (c.point2 === p1 && c.coordinates2 === c1 && c.point1 === p2 && c.coordinates1 === c2)
    //       )
    //   );
    //   return !!slices.length;
    // },
    cableClick(cable, slice) {
      if (this.readOnly) return;
      if (!cable || !slice) return;
      this.menuContextCable.editAction = () => {
        this.$refs.cableSubProperties.open(slice, cable, this.projectJson.cables);
        this.menuContextCable.dialog = false;
      };
      this.menuContextCable.removeAction = () => {
        cable.slices.remove(slice);
        this.invalidateProject();
        this.menuContextCable.dialog = false;
      };
      this.menuContextCable.dialog = true;
    },
    pointClick(point, coordinates) {
      if (this.readOnly) return this.$refs.spliceBox.open(point, coordinates);
      if (!point || !coordinates) return;
      if (this.connectCable) {
        if (!this.selectedCable) return;
        if (this.lastPointClicked) {
          this.createNewSlice(
              this.lastPointClicked.point,
              this.lastPointClicked.coordinates,
              point,
              coordinates,
              this.selectedCable,
              this.resetPointsAndRuler,
              () => {
                this.showAlert({message: CABLE_SLICE_EXISTS, timeout: 1000});
              },
              () => {
                this.updateLastPointClicked(point, coordinates);
              }
          );
          this.invalidateProject();
        } else {
          this.updateLastPointClicked(point, coordinates);
        }
      } else if (this.measuring && point.id === this.ruler.point.id) {
        this.removePointAndConnectOrphans(point, coordinates, this.ruler.cable, [this.ruler.cable]);
      } else {
        this.menuContextPoint.splicesAction = () => {
          this.$refs.spliceBox.open(point, coordinates);
          this.menuContextPoint.dialog = false;
        };
        this.menuContextPoint.editAction = () => {
          this.$refs.pointSubProperties.open(coordinates, point, this.projectJson.points);
          this.menuContextPoint.dialog = false;
        };
        this.menuContextPoint.removeAction = () => {
          this.removePointCoordinatesAndAssociatedSlices(point, coordinates, this.projectJson.cables);
          this.invalidateProject();
          this.menuContextPoint.dialog = false;
        };
        this.menuContextPoint.dialog = true;
      }
    },
    mapLoaded(map) {
      map.on('click', (e) => {
        if (this.createPoint && this.selectedPoint) {
          if (this.useSelectedCable && this.selectedCable && !this.isFirstCreatePoint) {
            this.addNewPointCoordinatesAndNewSlice(this.selectedPoint, this.selectedCable, e.latlng.lat, e.latlng.lng);
          } else {
            this.addNewPointCoordinates(this.selectedPoint, e.latlng.lat, e.latlng.lng);
            this.isFirstCreatePoint = false;
          }
          this.invalidateProject();
        } else if (this.measuring) {
          this.addNewPointCoordinatesAndNewSlice(this.ruler.point, this.ruler.cable, e.latlng.lat, e.latlng.lng);
        }
      });

      map.on('mousemove', (e) => {
        if (!this.connectCable) return;
        this.mousePoint = {
          lat: e.latlng.lat,
          lng: e.latlng.lng
        };
      });

      map.on('locationfound', location => {
        this.location = location;
        this.projectJson.center = location.latlng;
        this.locationLoading = false;
      });

      map.on('locationerror', () => {
        this.showAlert({message: LOCATION_ERROR});
        this.locationLoading = false;
      });

      this.mapObject = map;
    },
    updateLastPointClicked(point, coordinates) {
      this.lastPointClicked = {
        point: point,
        coordinates: coordinates
      };
    },
    createNewSlice(point1, coordinates1, point2, coordinates2, cable, sameClickAction, existsAction, okAction) {
      const isSameCoordinatesClick = point1.id === point2.id && coordinates1.id === coordinates2.id;
      if (isSameCoordinatesClick) {
        if (sameClickAction) {
          sameClickAction();
        }
        return;
      }
      // const exists = this.sliceExists(
      //     cable,
      //     point1.id,
      //     coordinates1.id,
      //     point2.id,
      //     coordinates2.id
      // );
      // if (exists) {
      //   if (existsAction) {
      //     existsAction();
      //   }
      //   return;
      // }
      cable.slices.push(getNewSliceCable({
        id: cable.slices.newId(),
        point1: point1.id,
        coordinates1: coordinates1.id,
        point2: point2.id,
        coordinates2: coordinates2.id,
      }));
      if (okAction) {
        okAction();
      }
    },
    removePointAndConnectOrphans(point, coordinates, cable, cables) {
      let pointLeft = null;
      let pointRight = null;
      const index = point.coordinates.indexOf(coordinates);
      if (index < 0) {
        return;
      }
      if (index >= 1) {
        pointLeft = point.coordinates[index - 1];
      }
      if (index <= point.coordinates.length - 2) {
        pointRight = point.coordinates[index + 1];
      }
      this.removePointCoordinatesAndAssociatedSlices(point, coordinates, cables);
      if (pointRight && pointLeft) {
        cable.slices.push(
            getNewSliceCable({
              id: cable.slices.newId(),
              point1: point.id,
              coordinates1: pointLeft.id,
              point2: point.id,
              coordinates2: pointRight.id
            })
        );
      }
    },
    addNewPointCoordinates(point, lat, lng) {
      point.coordinates.push(getNewPointCoordinates({
        id: point.coordinates.newId(),
        coordinates: {lat: lat, lng: lng}
      }));
    },
    addNewPointCoordinatesAndNewSlice(point, cable, lat, lng) {
      const pointCoordinates = getNewPointCoordinates({
        id: point.coordinates.newId(),
        coordinates: {
          lat: lat,
          lng: lng
        }
      });
      const last = point.coordinates.last();
      point.coordinates.push(pointCoordinates);
      if (point.coordinates.length > 1) {
        const slice = getNewSliceCable({
          id: cable.slices.newId(),
          point1: point.id,
          coordinates1: last.id,
          point2: point.id,
          coordinates2: pointCoordinates.id
        });
        cable.slices.push(slice);
      }
    },
    save() {
      if (this.readOnly) return;
      this.option = this.options.none;
      this.emitSave(true);
    },
    emitSave(showLoader) {
      if (this.readOnly) return;
      this.$emit('save', this.projectJson, showLoader);
      this.isProjectSave = true;
      window.onbeforeunload = undefined;
    },
    resetRuler() {
      this.ruler.point.coordinates = [];
      this.ruler.cable.slices = [];
    },
    removePointCoordinatesAndAssociatedSlices(point, coordinates, cables) {
      cables.map(c => {
        const slicesToDelete = c.slices.filter(s => ((
            s.point1 === point.id && s.coordinates1 === coordinates.id ||
            s.point2 === point.id && s.coordinates2 === coordinates.id
        )));
        slicesToDelete.map(sd => {
          c.slices.remove(sd);
        });
      });
      point.coordinates.remove(coordinates);
    },
    resetPointsAndRuler() {
      this.lastPointClicked = null;
      this.mousePoint = null;
      this.resetRuler();
    },
    openSelectCable() {
      if (this.readOnly) return;
      this.$refs.cableSelect.open();
    },
    openSelectPoint() {
      if (this.readOnly) return;
      this.$refs.pointSelect.open();
    },
    openPropertiesCable(cable) {
      if (this.readOnly) return;
      if (!cable) {
        cable = getNewCable();
      }
      this.$refs.cableProperties.open(cable);
    },
    openPropertiesPoint(point) {
      if (this.readOnly) return;
      if (!point) {
        point = getNewPoint();
      }
      this.$refs.pointProperties.open(point);
    },
    outputCable(cable, refCable) {
      if (this.readOnly) return;
      if (cable.id) {
        updateObject(refCable, cable);
      } else {
        this.projectJson.cables.push(getNewCable(cable, this.projectJson.cables));
      }
      this.invalidateProject();
    },
    outputPoint(point, refPoint) {
      if (this.readOnly) return;
      if (point.id) {
        updateObject(refPoint, point);
      } else {
        this.projectJson.points.push(getNewPoint(point, this.projectJson.points));
      }
      this.invalidateProject();
    },
    removeCable(cable) {
      if (this.readOnly) return;
      this.projectJson.cables.remove(cable);
      this.invalidateProject();
    },
    removePoint(point) {
      if (this.readOnly) return;
      point.coordinates.map(c => c).map(pc => {
        this.removePointCoordinatesAndAssociatedSlices(point, pc, this.projectJson.cables);
      });
      this.projectJson.points.remove(point);
      this.invalidateProject();
    },
    outputSlice(e) {
      if (this.readOnly) return;
      updateObject(e.refItem, e.item);

      if (e.parentItem.id !== e.type) {
        const oldParentItem = e.parentItem;
        const oldItem = e.refItem;

        const newParentItem = this.projectJson.cables.findId(e.type)
        if (newParentItem) {
          const newItem = getNewSliceCable(oldItem, newParentItem.slices);
          newParentItem.slices.push(newItem);
          oldParentItem.slices.remove(oldItem);
        }
      }

      this.invalidateProject();
    },
    outputPointCoordinates(e) {
      if (this.readOnly) return;
      updateObject(e.refItem, e.item);

      if (e.parentItem.id !== e.type) {
        const oldParentItem = e.parentItem;
        const oldItem = e.refItem;

        const newParentItem = this.projectJson.points.findId(e.type);
        if (newParentItem) {
          const newItem = getNewPointCoordinates(oldItem, newParentItem.coordinates);
          newParentItem.coordinates.push(newItem);
          this.projectJson.cables.map(c => {
            c.slices.map(s => {
              if (s.point1 === oldParentItem.id && s.coordinates1 === oldItem.id) {
                s.point1 = newParentItem.id;
                s.coordinates1 = newItem.id;
              }
              if (s.point2 === oldParentItem.id && s.coordinates2 === oldItem.id) {
                s.point2 = newParentItem.id;
                s.coordinates2 = newItem.id;
              }
            });
          });
          this.removePointCoordinatesAndAssociatedSlices(oldParentItem, oldItem, this.projectJson.cables);
        }
      }

      this.invalidateProject();
    },
    getSelectedItemOrFirst(array, id) {
      if (id) {
        const itemFounded = array.find(i => i.id === id);
        if (itemFounded) {
          return itemFounded;
        }
      }
      if (array.length > 0) {
        return array[0];
      }
      return null;
    },
    saveSpliceBox(coordinates, ceo) {
      if (this.readOnly) return;
      coordinates.ceo = ceo;
      this.invalidateProject();
    },
    handleFirstCreatePoint() {
      this.isFirstCreatePoint = true;
    },
    showLabels() {
      this.labels = !this.labels;
    }
  },
  computed: {
    isDarkMode() {
      return this.$vuetify.theme.dark;
    },
    selectedCable() {
      if (!this.projectJson) return null;
      return this.getSelectedItemOrFirst(this.projectJson.cables, this.selectedCableData);
    },
    selectedPoint() {
      if (!this.projectJson) return null;
      return this.getSelectedItemOrFirst(this.projectJson.points, this.selectedPointData);
    },
    selectedCableColor() {
      return this.selectedCable ? this.selectedCable.color : DEFAULT_COLOR;
    },
    selectedCableWeight() {
      return getCableWeight(this.selectedCable);
    },
    mapStyle() {
      let cursor = '';
      if (this.createPoint) {
        cursor = 'crosshair';
      } else if (this.connectCable || this.measuring) {
        cursor = 'pointer';
      }
      if (cursor) {
        return `cursor: ${cursor};`;
      } else {
        return '';
      }
    },
    createPoint() {
      return this.option === this.options.point_create;
    },
    measuring() {
      return this.option === this.options.ruler;
    },
    connectCable() {
      return this.option === this.options.connect_cable;
    },
    haveToDrawDynamicCable() {
      return this.connectCable && !!this.selectedCable && !!this.lastPointClicked && !!this.mousePoint;
    },
    totalDistanceMeasuring() {
      if (!this.measuring) return '';
      return labelDistance(this.ruler.cable.slices.reduce((total, s) => {
        const point1 = this.ruler.point.coordinates.findId(s.coordinates1);
        const point2 = this.ruler.point.coordinates.findId(s.coordinates2);
        return total + distance(point1, point2);
      }, 0));
    }
  },
  watch: {
    option(newValue, oldValue) {
      if (oldValue === this.options.connect_cable || oldValue === this.options.ruler || oldValue === this.options.point_create) {
        this.resetPointsAndRuler();
      }
    },
    selectedPoint() {
      this.handleFirstCreatePoint();
    },
    useSelectedCable() {
      this.handleFirstCreatePoint();
    }
  }
}
</script>

<style>
.map {
  z-index: 0;
}

.pointer-footer {
  cursor: pointer;
}
</style>
