<template>
  <l-tooltip>
    <div class="font-weight-bold">{{ `${parent.name} ${item.name || item.id}` }}</div>
    <div v-if="showDistance">{{ distance | labelDistance }}</div>
    <div v-if="item.reserve"> {{ `Reserva: ${item.reserve}m` }}</div>
    <div v-if="item.notes">{{ item.notes }}</div>
  </l-tooltip>
</template>

<script>
import {LTooltip} from 'vue2-leaflet';
import {distance, labelDistance} from '@/lib/util';

export default {
  props: {
    parent: Object,
    item: Object,
    coordinates1: {
      type: Object,
      required: false,
      default: null
    },
    coordinates2: {
      type: Object,
      required: false,
      default: null
    }
  },
  components: {
    LTooltip
  },
  name: 'ItemToolTip',
  filters: {
    labelDistance: labelDistance
  },
  computed: {
    distance() {
      if (this.showDistance) {
        return distance(this.coordinates1, this.coordinates2);
      }
      return 0;
    },
    showDistance() {
      return !!this.coordinates1 && !!this.coordinates2;
    }
  }
}
</script>
