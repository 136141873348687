import { render, staticRenderFns } from "./ItemMenuContext.vue?vue&type=template&id=45f54cd9&"
import script from "./ItemMenuContext.vue?vue&type=script&lang=js&"
export * from "./ItemMenuContext.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
installComponents(component, {VDialog,VList,VListItem})
