<template>
  <v-card class="fill-height">
    <app-nav-bar :title-bar="titleBar" action-menu main-menu :main-menu-items="menuItems"
                 :main-menu-items-append="menuItemsAppend">
      <v-list slot="navigation-drawer-prepend" dense>
        <v-list-item>
          <v-list-item-content>
            <v-card v-if="project" flat outlined>
              <v-card-text>
                <div>
                  <span class="font-weight-bold">ID:</span> {{ project.id }}
                </div>
                <div>
                  <span class="font-weight-bold">Nome:</span> {{ project.name }}
                </div>
                <div v-if="project.max_permissions">
                  <span class="font-weight-bold">Máximo de colaboradores:</span> {{ project.max_permissions }}
                </div>
                <div>
                  <span class="font-weight-bold">Editor:</span> {{ project.write ? 'Sim' : 'Não' }}
                </div>
              </v-card-text>
            </v-card>
          </v-list-item-content>
        </v-list-item>
        <v-divider/>
      </v-list>
    </app-nav-bar>

    <project-properties ref="editProject" label="Editar projeto" @output="editProject"/>

    <dialog-form v-model="permissionsDialog.dialog"
                 dialog-label="Colaboradores"
                 :dialog-loading="permissionsDialog.loading"
                 only-ok
                 max-width="600"
                 @ok="permissionsDialog.dialog = false">
      <div slot="fields">
        <div v-if="project" class="text-right">
          {{ `${permissionsDialog.permissions.length}/${project.max_permissions}` }}
        </div>
        <v-data-table :headers="permissionsDialog.headers" :items="permissionsDialog.permissions" dense>
          <template v-slot:item.write="{ item }">
            {{ item.write | showWrite }}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon title="Excluir" :color="isDarkMode ? '' : 'primary'" @click="deletePermission(item)">
              mdi-delete
            </v-icon>
          </template>
          <template v-slot:no-data>
            Nenhum usuário
          </template>
        </v-data-table>
      </div>
      <v-btn v-if="allowAddUser" slot="btn" color="primary" text @click="openPermissionAddDialog">
        Adicionar usuário
      </v-btn>
    </dialog-form>

    <dialog-form v-model="addPermissionDialog.dialog"
                 :dialog-loading="addPermissionDialog.loading"
                 dialog-label="Adicionar usuário"
                 @ok="addPermission">
      <v-container slot="fields">
        <v-row>
          <v-col cols="12">
            <v-text-field
                v-if="addPermissionDialog.dialog"
                v-model="addPermissionDialog.email"
                ref="userEmailPermission"
                label="E-mail"
                name="email"
                type="email"
                :maxLength="addPermissionDialog.maxEmailLength"
                @input="addPermissionDialog.errors = []"
                :error-messages="addPermissionDialog.errors"
            />
            <v-checkbox
                v-model="addPermissionDialog.write"
                label="Editor do projeto"
            />
          </v-col>
        </v-row>
      </v-container>
    </dialog-form>

    <v-main class="fill-height">
      <map-project v-if="project" :project="project.json" :write="project.write" @save="saveProjectJson"/>
    </v-main>
  </v-card>
</template>

<script>
import AppNavBar from '@/components/AppNavBar';
import {deleteProject, getProject, putProject, putProjectJson} from '@/lib/backend/project';
import {goToHome, goToProjects} from '@/lib/redirects';
import {mapActions, mapState} from 'vuex';
import DialogForm from '@/components/DialogForm';
import {getValidProject, parseIntegerPositive, validEmail} from '@/lib/validation/validation';
import {deletePermission, getPermissions, getUserByEmail, postPermission} from '@/lib/backend/permissions';
import MapProject from '@/views/project/Map';
import {MAX_EMAIL_LENGTH} from '@/lib/validation/constants';
import ProjectProperties from '@/components/ProjectProperties';
import {updateObject} from '@/lib/util';

const NOT_ALLOWED = 'Operação não permitida';
const INVALID_PROJECT = 'Projeto inválido';
const INVALID_EMAIL = 'E-mail inválido';
const NOT_FOUND_EMAIL = 'E-mail não encontrado';
const CONFIRM_QUESTION = 'Confirmar?';
const DELETE_PROJECT = 'Excluir projeto';
const USER_ALREADY_PERMISSION = 'O usuário informado já está na lista de permissões';
const USER_OWNER_PROJECT = 'O e-mail informado pertence ao proprietário do projeto';
const SAVED = 'Salvo';
const EDITOR = 'Editor';
const READ_ONLY = 'Apenas leitura';
const customAddUserPermission = user => `Conceder permissão neste projeto para ${user.name}.`;
const customDeletePermission = val => `Remover permissão de ${val} para este projeto`;
const LEAVE_PROJECT = 'Abandonar projeto';

export default {
  name: 'Project',
  components: {
    ProjectProperties,
    MapProject,
    DialogForm,
    AppNavBar
  },
  filters: {
    showWrite: function (val) {
      return val ? EDITOR : READ_ONLY;
    }
  },
  data() {
    return {
      permissionsDialog: {
        dialog: false,
        loading: false,
        permissions: [],
        headers: [
          {text: 'E-mail', value: 'email'},
          {text: 'Nome', value: 'name'},
          {text: 'Editor', value: 'write'},
          {text: 'Ações', value: 'actions', sortable: false}
        ]
      },
      addPermissionDialog: {
        dialog: false,
        loading: false,
        email: '',
        write: false,
        maxEmailLength: MAX_EMAIL_LENGTH,
        errors: [],
      },
      project: null,
      menuItemsAppend: [
        {
          title: 'Projetos',
          icon: 'mdi-format-list-checks',
          click: () => {
            goToProjects();
          }
        },
        {
          title: 'Página inicial',
          icon: 'mdi-home',
          click: () => {
            goToHome();
          }
        }
      ]
    }
  },
  mounted() {
    this.loadProject();
  },
  computed: {
    ...mapState({
      user: state => state.config.user
    }),
    isDarkMode() {
      return this.$vuetify.theme.dark;
    },
    menuItems() {
      if (this.project && this.project.user === this.user.id) {
        return [{
          title: 'Editar projeto',
          icon: 'mdi-pencil',
          click: () => {
            this.openEditProjectDialog()
          }
        }, {
          title: 'Colaboradores',
          icon: 'mdi-card-account-details',
          click: () => {
            this.openDialogPermissions();
          }
        }, {
          title: 'Excluir projeto',
          icon: 'mdi-delete',
          click: () => {
            this.deleteProject();
          }
        }
        ];
      }
      return [
        {
          title: 'Abandonar projeto',
          icon: 'mdi-exit-to-app',
          click: () => {
            this.leaveProject();
          }
        }
      ];
    },
    titleBar() {
      if (this.project) return this.project.name;
      return '';
    },
    allowAddUser() {
      return this.project && this.permissionsDialog.permissions.length < this.project.max_permissions;
    }
  },
  methods: {
    ...mapActions('confirmation', ['showConfirmation']),
    ...mapActions('alert', ['showAlert']),
    async getProject(id) {
      if (id) {
        const projectId = parseIntegerPositive(id);
        if (projectId !== null) {
          const response = await getProject(projectId);
          if (response.status === 200) {
            return response.data;
          }
        }
      }
      return null;
    },
    async loadProject() {
      const data = await this.getProject(this.$route.params.id);
      if (data) {
        data.json = getValidProject(data.json);
        this.project = data;
      } else {
        this.showAlert({message: INVALID_PROJECT, color: 'error'});
        goToProjects();
      }
    },
    openEditProjectDialog() {
      this.$refs.editProject.open(this.project);
    },
    async editProject(project, refProject) {
      const response = await putProject({
        id: project.id,
        name: project.name
      });
      if (response) {
        if (response.status === 200) {
          updateObject(refProject, project);
        } else {
          this.showAlert({message: response.message});
        }
      }
    },
    deleteProject() {
      if (this.project) {
        this.showConfirmation({
          title: CONFIRM_QUESTION,
          text: DELETE_PROJECT,
          action: async () => {
            const response = await deleteProject(this.project.id);
            if (response) {
              if (response.status !== 200) {
                this.showAlert({message: response.message});
              }
              goToProjects();
            }
          }
        });
      }
    },
    async openDialogPermissions() {
      if (this.project) {
        this.permissionsDialog.dialog = true;
        this.permissionsDialog.loading = true;
        const response = await getPermissions(this.project.id);
        if (response) {
          if (response.status === 200) {
            this.permissionsDialog.permissions = response.data;
          } else {
            this.showAlert({message: response.message});
            this.permissionsDialog.dialog = false;
          }
        }
        this.permissionsDialog.loading = false;
      }
    },
    async addPermission() {
      if (!validEmail(this.addPermissionDialog.email, true)) return this.addPermissionDialog.errors = [INVALID_EMAIL];
      if (this.permissionsDialog.permissions.find(p => this.addPermissionDialog.email === p.email)) return this.addPermissionDialog.errors = [USER_ALREADY_PERMISSION];
      if (this.user.email === this.addPermissionDialog.email) return this.addPermissionDialog.errors = [USER_OWNER_PROJECT];

      this.addPermissionDialog.loading = true;
      const response = await getUserByEmail({email: this.addPermissionDialog.email});
      this.addPermissionDialog.loading = false;
      if (response) {
        if (response.status === 200) {
          this.showConfirmation({
            title: CONFIRM_QUESTION,
            text: customAddUserPermission(response.data),
            action: async () => {
              this.addPermissionDialog.loading = true;
              const responseCreate = await postPermission(this.project.id, {
                ...response.data,
                write: this.addPermissionDialog.write
              });
              if (responseCreate) {
                if (responseCreate.status === 200) {
                  this.permissionsDialog.permissions.push(responseCreate.data);
                  this.addPermissionDialog.dialog = false;
                } else {
                  this.showAlert({message: responseCreate.message});
                }
              }
              this.addPermissionDialog.loading = false;
            }
          });
        } else if (response.status === 404) {
          this.addPermissionDialog.errors = [NOT_FOUND_EMAIL];
        } else if (response.status === 400) {
          this.addPermissionDialog.errors = [INVALID_EMAIL];
        } else {
          this.showAlert({message: response.message});
        }
      }
    },
    openPermissionAddDialog() {
      this.addPermissionDialog.email = '';
      this.addPermissionDialog.write = false;
      this.addPermissionDialog.errors = [];
      this.addPermissionDialog.dialog = true;
      setTimeout(() => {
        this.$refs.userEmailPermission.focus();
      }, 100);
    },
    deletePermission(permission) {
      this.showConfirmation({
        title: CONFIRM_QUESTION,
        text: customDeletePermission(permission.name),
        action: async () => {
          this.permissionsDialog.loading = true;
          const response = await deletePermission(this.project.id, permission.id);
          if (response) {
            if (response.status === 200) {
              this.permissionsDialog.permissions.remove(permission)
            } else {
              this.showAlert({message: response.message});
            }
          }
          this.permissionsDialog.loading = false;
        }
      });
    },
    async saveProjectJson(projectJson, showLoader) {
      if (!this.project) return;
      const response = await putProjectJson(this.project.id, projectJson, showLoader);
      if (response && showLoader) {
        if (response.status === 200) {
          this.showAlert({
            message: SAVED,
            timeout: 1000,
            color: this.isDarkMode ? '' : 'primary'
          });
        } else if (response.status === 403) {
          this.showAlert({
            message: NOT_ALLOWED
          });
          goToProjects();
        } else {
          this.showAlert({message: response.message});
        }
      }
    },
    leaveProject() {
      this.showConfirmation({
        title: CONFIRM_QUESTION,
        text: LEAVE_PROJECT,
        action: async () => {
          const response = await deletePermission(this.project.id, this.project.permission_id, true);
          if (response) {
            if (response.status === 200) {
              goToProjects();
            } else {
              this.showAlert({message: response.message});
            }
          }
        }
      });
    }
  }
}
</script>
