import {client, request} from '@/lib/backend/http';

export const getProjects = () => {
    return request(client().get, '/project');
};

export const postProject = (project) => {
    return request(client().post, '/project', project);
};

export const deleteProject = (id) => {
    return request(client().delete, `/project/${id}`);
};

export const putProject = (project) => {
    return request(client().put, `/project/${project.id}`, project);
};

export const getProject = (id) => {
    return request(client().get, `/project/${id}`);
};

export const putProjectJson = (projectId, projectJson, showLoader = true) => {
    return request(client().put, `/project/${projectId}/json`, projectJson, true, showLoader);
};
