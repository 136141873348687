export const validEmail = email => {
    if (email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
    return false;
};

import {
    CEO_ITEM_TYPE_CABLE,
    getNewPoint,
    getNewPointCEOConnection,
    getNewPointCoordinates,
    getNewPointCoordinatesCEO,
    getNewPointCEOItem,
    CEO_ITEM_TYPE_BALANCED_SPLITTER,
    CEO_ITEM_TYPE_UNBALANCED_SPLITTER,
    CEO_ITEM_TYPE_CUSTOM,
    getSplitterUnbalancedMajorOutputFromMinorOutput,
} from '@/models/point';

import {
    CABLE_DROP, cablesDescription, getNewCable, getNewSliceCable
} from '@/models/cable';

import {
    DEFAULT_CABLE_NAME,
    DEFAULT_COLOR,
    DEFAULT_LAT,
    DEFAULT_LNG,
    DEFAULT_POINT_LENGTH,
    DEFAULT_CABLE_OFFSET,
    DEFAULT_ZOOM,
    MAX_POINT_LENGTH,
    MAX_ZOOM,
    MIN_CABLE_OFFSET,
    MAX_CABLE_OFFSET,
    MAX_CABLE_NAME,
    DEFAULT_POINT_NAME,
    MAX_POINT_NAME,
    MIN_POINT_LENGTH,
    MIN_FIBER_QUANTITY,
    MAX_FIBER_QUANTITY,
    DEFAULT_NOTES,
    MAX_NOTES_LENGTH,
    DEFAULT_POINT_COORDINATE_RESERVE,
    MIN_FIBER_SPLICE_BOX_HEIGHT_CONNECTION,
    MAX_FIBER_SPLICE_BOX_HEIGHT_CONNECTION,
    MIN_SPLITTER_OUTPUTS,
    MIN_SPLITTER_FACTOR,
    MAX_SPLITTER_FACTOR,
    MAX_SPLITTER_OUTPUTS,
    MAX_POINT_COORDINATE_NAME_LENGTH,
    DEFAULT_POINT_COORDINATE_NAME,
    DEFAULT_SLICE_OFFSET,
    DEFAULT_CABLE_EMPTY_WEIGHT,
    MIN_CABLE_WEIGHT,
    MAX_CABLE_WEIGHT,
    MAX_CUSTOM_EQUIPMENT_NAME,
    MIN_CUSTOM_EQUIPMENT_OUTPUTS,
    MAX_CUSTOM_EQUIPMENT_OUTPUTS
} from '@/lib/validation/constants';

export const getZoom = (zoom) => {
    const n = parseIntegerNonNegative(zoom);
    if (n !== null && n <= MAX_ZOOM) {
        return n;
    }
    return null;
}

export const getColor = (color) => {
    if (color && typeof color === 'string') {
        return /^#[0-9A-F]{6}$/i.test(color) ? color : null;
    }
    return null;
}

export const getPointName = name => {
    if (typeof name === 'string' && name.length > 0 && name.length <= MAX_POINT_NAME) {
        return name;
    }
    return null;
}

export const getCableType = type => {
    if (type && typeof type === 'number' && type > 0) {
        const typesFilter = cablesDescription.filter(c => c.type === type);
        if (typesFilter.length > 0) {
            return type;
        }
    }
    return null;
}

export const getCableFibers = fibers => {
    const n = parseIntegerPositive(fibers);
    if (n !== null && n >= MIN_FIBER_QUANTITY && n <= MAX_FIBER_QUANTITY) {
        return n;
    }
    return null;
}

export const getCustomEquipmentName = name => {
    if (typeof name === 'string' && name.length <= MAX_CUSTOM_EQUIPMENT_NAME) {
        return name;
    }
    return null;
}

export const getCustomEquipmentOutput = output => {
    const n = parseIntegerPositive(output);
    if (n !== null && n >= MIN_CUSTOM_EQUIPMENT_OUTPUTS && n <= MAX_CUSTOM_EQUIPMENT_OUTPUTS) {
        return n;
    }
    return null;
}

export const getCableName = name => {
    if (typeof name === 'string' && name.length > 0 && name.length <= MAX_CABLE_NAME) {
        return name;
    }
    return null;
}

export const getCableOffset = offset => {
    const n = parseNumber(offset, parseInt);
    if (n !== null && n >= MIN_CABLE_OFFSET && n <= MAX_CABLE_OFFSET) {
        return n;
    }
    return null;
}

export const getCableWeight = weight => {
    const n = parseIntegerPositive(weight);
    if (n !== null && n >= MIN_CABLE_WEIGHT && n <= MAX_CABLE_WEIGHT) {
        return n;
    }
    return null;
}

export const getNotes = notes => {
    if (typeof notes === 'string' && notes.length <= MAX_NOTES_LENGTH) {
        return notes;
    }
    return null;
}

export const getSliceOffset = offset => {
    return typeof offset === 'string' ? DEFAULT_SLICE_OFFSET : getCableOffset(offset);
}

export const getCableSliceWeight = weight => {
    return typeof weight === 'string' ? DEFAULT_CABLE_EMPTY_WEIGHT : getCableWeight(weight);
}

export const getPointCoordinateName = name => {
    if (typeof name === 'string' && name.length <= MAX_POINT_COORDINATE_NAME_LENGTH) {
        return name;
    }
    return null;
}

export const parseNumber = (n, parseFunction) => {
    try {
        if (n === undefined) {
            return null;
        }
        let number = parseFunction(n);
        if (isNaN(number)) {
            return null;
        }
        if (!isFinite(number)) {
            return null;
        }
        return number;
    } catch (e) {
        return null;
    }
}

export const parseIntegerPositive = (n) => {
    const number = parseNumber(n, parseInt);
    if (number != null && number > 0) {
        return number;
    }
    return null;
}

export const parseIntegerNonNegative = (n) => {
    const number = parseNumber(n, parseInt);
    if (number != null && number >= 0) {
        return number;
    }
    return null;
}

export const getPointLength = (length) => {
    let convertedLength = parseIntegerPositive(length);
    if (convertedLength !== null && convertedLength >= MIN_POINT_LENGTH && convertedLength <= MAX_POINT_LENGTH) {
        return convertedLength;
    }
    return null;
}

export const getCoordinates = (c) => {
    if (c && c.lat && c.lng) {
        const lat = parseNumber(c.lat, parseFloat);
        const lng = parseNumber(c.lng, parseFloat);
        if (lat !== null && lng !== null) {
            if (lat >= -90 && lat <= 90 && lng >= -180 && lng <= 180) {
                return {
                    lat: lat, lng: lng
                }
            }
        }
    }
    return null;
}

export const getFiberID = (id) => {
    if (typeof id === 'string' && id.length > 0) {
        return id;
    }
    return null;
}

export const getCEOConnectionHeight = (height) => {
    const convertedHeight = parseIntegerPositive(height);
    if (convertedHeight !== null && convertedHeight >= MIN_FIBER_SPLICE_BOX_HEIGHT_CONNECTION && convertedHeight <= MAX_FIBER_SPLICE_BOX_HEIGHT_CONNECTION) {
        return convertedHeight;
    }
    return null;
}

export const getCEOSplitterOutputs = (outputs) => {
    const convertedOutputs = parseIntegerPositive(outputs);
    if (convertedOutputs !== null && convertedOutputs >= MIN_SPLITTER_OUTPUTS && convertedOutputs <= MAX_SPLITTER_OUTPUTS) {
        return convertedOutputs;
    }
    return null;
}

export const getCEOSplitterFactor = (factor) => {
    const convertedFactor = parseIntegerPositive(factor);
    if (convertedFactor !== null && convertedFactor >= MIN_SPLITTER_FACTOR && convertedFactor <= MAX_SPLITTER_FACTOR) {
        return convertedFactor;
    }
    return null;
}

export const getSpliceBoxItemType = (type) => {
    if (type && typeof type === 'number' && type > 0) {
        if (type === CEO_ITEM_TYPE_CABLE || type === CEO_ITEM_TYPE_BALANCED_SPLITTER || type === CEO_ITEM_TYPE_UNBALANCED_SPLITTER || type === CEO_ITEM_TYPE_CUSTOM) {
            return type;
        }
    }
    return null;
}

export const getConvertedOrDefault = (verifyFunction, verifyValue, defaultValue) => {
    let value = defaultValue;
    let verifiedValue = verifyFunction(verifyValue);
    if (verifiedValue !== null) {
        value = verifiedValue;
    }
    return value;
}

export const getArray = (a) => {
    if (a && a instanceof Array && a.length > 0) {
        return a;
    }
    return null;
}

export const getPointCEOConnections = (pcc) => {
    pcc = getArray(pcc);
    if (pcc) {
        const connections = pcc.map(c => {
            const id = parseIntegerPositive(c.id);
            const fiber1 = getFiberID(c.fiber1);
            const fiber2 = getFiberID(c.fiber2);
            const height = getCEOConnectionHeight(c.height);
            const color = getConvertedOrDefault(getColor, c.color, DEFAULT_COLOR);
            if (!id || !fiber1 || !fiber2 || !height) {
                return null;
            }
            return getNewPointCEOConnection({
                id: id, fiber1: fiber1, fiber2: fiber2, height: height, color: color
            });
        });
        return connections.filter(c => !!c);
    }
    return null;
}

export const getPointCEOItems = (pci) => {
    pci = getArray(pci);
    if (pci) {
        const items = pci.map(i => {
            const id = parseIntegerPositive(i.id);
            const type = getSpliceBoxItemType(i.type);

            if (!id || !type) {
                return null;
            }

            let name = '';
            let cable = 0;
            let slice = 0;

            if (type === CEO_ITEM_TYPE_CABLE) {
                cable = parseIntegerPositive(i.cable);
                slice = parseIntegerPositive(i.slice);

                if (!cable || !slice) {
                    return null;
                }
            } else if (type === CEO_ITEM_TYPE_BALANCED_SPLITTER) {
                cable = 1;
                slice = getCEOSplitterOutputs(i.slice);

                if (!slice) {
                    return null;
                }
            } else if (type === CEO_ITEM_TYPE_UNBALANCED_SPLITTER) {
                cable = getCEOSplitterFactor(i.cable);
                slice = parseIntegerPositive(i.slice);

                if (!cable || slice !== getSplitterUnbalancedMajorOutputFromMinorOutput(cable)) {
                    return null;
                }
            } else if (type === CEO_ITEM_TYPE_CUSTOM) {
                name = getCustomEquipmentName(i.name);
                cable = getCustomEquipmentOutput(i.cable);

                if (name === null || !cable) {
                    return null;
                }
            }
            return getNewPointCEOItem({
                id: id, name: name, type: type, cable: cable, slice: slice
            });
        });
        return items.filter(i => !!i);
    }
    return null;
}

export const getPointCoordinatesCEO = (ceo) => {
    if (ceo) {
        const items = getConvertedOrDefault(getPointCEOItems, ceo.items, []);
        const connections = getConvertedOrDefault(getPointCEOConnections, ceo.connections, []);
        return getNewPointCoordinatesCEO({
            items: items, connections: connections
        });
    }
    return null;
}

export const getPointCoordinates = (pc) => {
    pc = getArray(pc);
    if (pc) {
        const coordinates = pc.map(c => {
            const id = parseIntegerPositive(c.id);
            const coordinates = getCoordinates(c.coordinates);
            const notes = getConvertedOrDefault(getNotes, c.notes, DEFAULT_NOTES);
            const name = getConvertedOrDefault(getPointCoordinateName, c.name, DEFAULT_POINT_COORDINATE_NAME);
            const reserve = getConvertedOrDefault(parseIntegerNonNegative, c.reserve, DEFAULT_POINT_COORDINATE_RESERVE);
            const ceo = getConvertedOrDefault(getPointCoordinatesCEO, c.ceo, getNewPointCoordinatesCEO());
            if (!id || !coordinates) {
                return null;
            }
            return getNewPointCoordinates({
                id: id, coordinates: coordinates, name: name, notes: notes, reserve: reserve, ceo: ceo
            });
        });
        return coordinates.filter(c => !!c);
    }
    return null;
}

export const getPoint = (p) => {
    const id = parseIntegerPositive(p.id);
    if (!id) {
        return null;
    }
    const color = getConvertedOrDefault(getColor, p.color, DEFAULT_COLOR);
    const length = getConvertedOrDefault(getPointLength, p.length, DEFAULT_POINT_LENGTH);
    const name = getConvertedOrDefault(getPointName, p.name, DEFAULT_POINT_NAME);
    let coordinates = getConvertedOrDefault(getPointCoordinates, p.coordinates, []);
    return getNewPoint({
        id: id, color: color, length: length, name: name, coordinates: coordinates
    });
}

export const getCableSlices = (cs) => {
    cs = getArray(cs);
    if (cs) {
        const slices = cs.map(s => {
            const id = parseIntegerPositive(s.id);
            const point1 = parseIntegerPositive(s.point1);
            const coordinates1 = parseIntegerPositive(s.coordinates1);
            const point2 = parseIntegerPositive(s.point2);
            const coordinates2 = parseIntegerPositive(s.coordinates2);
            const notes = getConvertedOrDefault(getNotes, s.notes, DEFAULT_NOTES);
            const offset = getConvertedOrDefault(getSliceOffset, s.offset, DEFAULT_SLICE_OFFSET);
            const weight = getConvertedOrDefault(getCableSliceWeight, s.weight, DEFAULT_CABLE_EMPTY_WEIGHT);
            if (!id || !point1 || !point2 || !coordinates1 || !coordinates2) {
                return null;
            }
            return getNewSliceCable({
                id: id,
                point1: point1,
                coordinates1: coordinates1,
                point2: point2,
                coordinates2: coordinates2,
                notes: notes,
                offset: offset,
                weight: weight
            });
        });
        return slices.filter(s => !!s);
    }
    return null;
}

export const getCable = (c) => {
    const id = parseIntegerPositive(c.id);
    if (!id) {
        return null;
    }
    let color = getConvertedOrDefault(getColor, c.color, DEFAULT_COLOR);
    let name = getConvertedOrDefault(getCableName, c.name, DEFAULT_CABLE_NAME);
    let type = getConvertedOrDefault(getCableType, c.type, CABLE_DROP);
    let fibers = getConvertedOrDefault(getCableFibers, c.fibers, MIN_FIBER_QUANTITY);
    let offset = getConvertedOrDefault(getCableOffset, c.offset, DEFAULT_CABLE_OFFSET);
    let slices = getConvertedOrDefault(getCableSlices, c.slices, []);
    const weight = getConvertedOrDefault(getCableSliceWeight, c.weight, DEFAULT_CABLE_EMPTY_WEIGHT);
    return getNewCable({
        id: id, color: color, name: name, type: type, fibers: fibers, offset: offset, slices: slices, weight: weight
    });
}

export const getValidProject = (projectStr) => {
    let validProject = {
        config: {
            version: process.env.VUE_APP_GIT_HASH
        }, cables: [], points: [], center: {
            lat: DEFAULT_LAT, lng: DEFAULT_LNG
        }, zoom: DEFAULT_ZOOM
    };
    if (projectStr) {
        try {
            const project = JSON.parse(projectStr);
            if (project) {
                const cables = getConvertedOrDefault(getArray, project.cables, null);
                const points = getConvertedOrDefault(getArray, project.points, null);
                const center = getConvertedOrDefault(getCoordinates, project.center, null);
                const zoom = getConvertedOrDefault(getZoom, project.zoom, null);

                if (cables) {
                    validProject.cables = cables.map(c => {
                        return getCable(c);
                    }).filter(c => !!c);
                }

                if (points) {
                    validProject.points = points.map(p => {
                        return getPoint(p);
                    }).filter(p => !!p);
                }

                if (center) {
                    validProject.center = center;
                }

                if (zoom !== null) {
                    validProject.zoom = zoom;
                }
            }
        } catch (e) {
            //
        }
    }
    return validProject;
}
