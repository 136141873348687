<template>
  <handler title="Acesso negado" subtitle="Este recurso não está disponível para o seu usuário"/>
</template>

<script>
import Handler from '@/views/handlers/Handler';

export default {
  name: 'Forbidden',
  components: {Handler}
}
</script>
